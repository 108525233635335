import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

import packgeJson from "../package.json";

export const apiName = "eem-cxp-gpc-agw-dmc-v2";

export const appVersion = packgeJson.version;

export const API_URL = {
  BASE_URL:
    "https://jum9d5eui6.execute-api.eu-west-2.amazonaws.com/dev/migration/api/v1",
  // export
  GET_EXPORT_SCRIPT: "/resource/script",
  POST_EXPORT_EXECUTE: "/export",
  GET_EXPORT_STATUS: "/export/",

  // import
  POST_IMPORT_EXECUTE: "/import",
  GET_EXPORT_IDS: "/export",
  GET_IMPORT_STATUS: "/import/",
};

export const removeKey = (obj: any, keyToRemove: any) => {
  const { [keyToRemove]: _, ...newObj } = obj;
  return newObj;
};

export const removeKeyByValue = (obj: any, valueToRemove: any) => {
  const filteredEntries = Object.entries(obj).filter(
    ([_key, value]) => value !== valueToRemove
  );

  return Object.fromEntries(filteredEntries);
};

export const generateUniqueId = (): string => {
  const now = new Date();
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const milliseconds = now.getMilliseconds().toString().padStart(3, "0");
  const uniqueId = `${seconds}${milliseconds}`;
  const timestamp = now.getTime().toString().slice(-5);
  return `${uniqueId}-${timestamp}`;
};

export const convertCapitalizeWords = (input: string) =>
  input
    .toLowerCase()
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");

export const formateDate = (date: string) =>
  moment(date, "D-M-YYYY h:mm_A").format("DD/MM/YYYY hh:mmA");

export const getValueByKey = (key: string, obj: any) =>
  obj && obj[key] ? (key === "Time" ? formateDate(obj[key]) : obj[key]) : null;

export const getStyles = (element: any, _type?: string): string => {
  if (element.type === "select") {
    return `width:100%;padding-top: ${
      element.paddingTop > 3 ? 3 : element.paddingTop
    }px;
             padding-bottom: ${
               element.paddingBottom > 3 ? 3 : element.paddingBottom
             }px;
             padding-left: ${element.paddingLeft}px;
             padding-right: ${element.paddingRight}px;
             box-sizing: border-box;
             font-weight: ${element.fontWeight};
             color: ${element.color || ""} ;
             font-family: ${element.fontFamily || ""};
             font-size: ${element.fontSize || 14}px !important;
             letter-spacing: ${element.letterSpacing}px;`;
  }
  if (element.type === "textarea") {
    return `border:${
      element.isBorder
        ? `${element?.borderWidth || 1}px solid ${
            element?.borderColor || "#000000"
          } !important`
        : ""
    };
            text-align:${element?.textAlign || "left"};
            background-color:${
              element?.bgColor ? element?.bgColor : ""
            } !important;
            height:${element.height && element.height}px !important;
            width:100%;padding-top: ${element.paddingTop}px !important;
            padding-bottom: ${element.paddingBottom}px !important;
            padding-left: ${element.paddingLeft}px !important;
            padding-right: ${element.paddingRight}px !important;
            box-sizing: border-box !important;
            font-weight: ${element.fontWeight} !important;
            color: ${element.color || ""} !important;
            font-family: ${element.fontFamily || ""} !important;
            font-size: ${element.fontSize || 14}px !important;
            letter-spacing: ${element.letterSpacing}px !important;
            border-radius:${element?.radius || 0}px !important`;
  }

  return `border:${
    element.isBorder
      ? `${element?.borderWidth || 1}px solid ${
          element?.borderColor || "#000000"
        }`
      : ""
  };
          text-align:${element?.textAlign || "left"};
          background-color:${element?.bgColor ? element?.bgColor : ""};
          width:100%;padding-top: ${element.paddingTop}px !important;
          height:${element.height}px;
          padding-bottom: ${element.paddingBottom}px !important;
          padding-left: ${element.paddingLeft}px !important;
          padding-right: ${element.paddingRight}px !important;
          box-sizing: border-box !important;
          font-weight: ${element.fontWeight} !important;
          color: ${element.color || ""} !important;
          font-family: ${element.fontFamily || ""} !important;
          font-size: ${element.fontSize || 14}px !important;
          letter-spacing: ${element.letterSpacing}px !important;
          border-radius:${element?.radius || 0}px`;
};

export const getEnableResizing = (value: boolean) => {
  return {
    bottom: value,
    bottomLeft: value,
    bottomRight: value,
    left: value,
    right: value,
    top: value,
    topLeft: value,
    topRight: value,
  };
};

export const resgionList = [
  { label: "Select Region", value: "select_region" },
  { label: "us_east_1", value: "us_east_1" },
  { label: "us_east_2", value: "us_east_2" },
  { label: "us_west_2", value: "us_west_2" },
  { label: "ca_central_1", value: "ca_central_1" },
  { label: "eu_west_1", value: "eu_west_1" },
  { label: "eu_west_2", value: "eu_west_2" },
  { label: "eu_central_1", value: "eu_central_1" },
  { label: "eu_central_2", value: "eu_central_2" },

  { label: "ap_south_1", value: "ap_south_1" },
  { label: "ap_northeast_1", value: "ap_northeast_1" },
  { label: "ap_northeast_2", value: "ap_northeast_2" },
  { label: "ap_northeast_3", value: "ap_northeast_3" },

  { label: "ap_southeast_2", value: "ap_southeast_2" },
  { label: "sa_east_1", value: "sa_east_1" },
  { label: "me_central_1", value: "me_central_1" },
];

export const locations = {
  us_east_1: "mypurecloud.com",
  us_east_2: "use2.us-gov-pure.cloud",
  us_west_2: "usw2.pure.cloud",
  ca_central_1: "cac1.pure.cloud",
  eu_west_1: "mypurecloud.ie",
  eu_west_2: "euw2.pure.cloud",
  eu_central_1: "mypurecloud.de",
  eu_central_2: "euc2.pure.cloud",
  ap_south_1: "aps1.pure.cloud",
  ap_northeast_1: "mypurecloud.jp",
  ap_northeast_2: "apne2.pure.cloud",
  ap_northeast_3: "apne3.pure.cloud",
  ap_southeast_2: "mypurecloud.com.au",
  sa_east_1: "sae1.pure.cloud",
  me_central_1: "mec1.pure.cloud",
};

export const getDuration = (startTime: string) => {
  if (startTime) {
    const sTime = dayjs(startTime);
    const endTime = dayjs();
    // Calculate the difference in minutes and seconds
    const hours = endTime.diff(sTime, "hour");
    const minutes = endTime.diff(sTime, "minute");
    const seconds = endTime.diff(sTime, "second") % 60;
    // 0h 0m 35s
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  return null;
};

export const isValidId = (id: any) => {
  const regex = /^\/\d{8}-\d{6}$/;
  return regex.test(id) && typeof id === "string";
};

export const renameZips = (obj: any) => {
  for (const key in obj) {
    if (obj[key].endsWith(".zip")) {
      const pathParts = obj[key].split("/");
      pathParts[pathParts.length - 1] = `${key.replace(/-url$/, "")}.zip`;
      obj[key] = pathParts.join("/");
    }
  }
  return obj;
};

export const urls2: any = {
  // "changelog-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/change.log",
  // "changeset-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/changeset.log",
  // "fail-validation-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/fail_validation.log",
  // "full-validation-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/full_validation.log",
  // "validation-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/validation.log",
  // "output-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/20241031-144926-102-test-dpt-import.zip",
  // "report-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/Report.html",
  // "md-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/Report.md",
  // "dms-log-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/dmc-log.zip",
  // "bad-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/bad.csi",
  // "good-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/good.csi",
  // "log-file-url":
  //   "resource/import/20241031-144926-102-test-dpt-import/output.log",
  // "logs-url": "resource/import/20241031-144926-102-test-dpt-import/logs.zip",
  "output-url":
    "resource/export/20241021-121553-157-test-department/20241021-121553-157-test-department.zip",
  "report-file-url":
    "resource/export/20241021-121553-157-test-department/Report.html",
  "md-file-url":
    "resource/export/20241021-121553-157-test-department/Report.md",
  "dms-log-url":
    "resource/export/20241021-121553-157-test-department/dms-log.zip",
  "bad-file-url": "resource/export/20241021-121553-157-test-department/bad.csi",
  "good-file-url":
    "resource/export/20241021-121553-157-test-department/good.csi",
  "log-file-url":
    "resource/export/20241021-121553-157-test-department/output.log",
  "logs-url": "resource/export/20241021-121553-157-test-department/logs.zip",
};

export const subtractPixels = (value: string, pixelsToSubtract: number) => {
  // Extract the numeric part from the pixel value
  let numericValue = parseInt(value, 10);

  // Perform the subtraction
  let newValue = numericValue - pixelsToSubtract;

  // Return the new pixel value as a string with 'px'
  return newValue + "px";
};

export const formatDate = (date: string | Dayjs, format: string) => {
  const formatMapping: any = {
    "YYYY-MM-dd (System setting)": dayjs(date).format("YYYY-MM-DD"),
    "MMY/MM/dd": dayjs(date).format("YY/MM/DD"),
    "YYYY.MM.dd": dayjs(date).format("YYYY.MM.DD"),
    "dd-MM-YYYY": dayjs(date).format("DD-MM-YYYY"),
    "dd/MM/YYYY": dayjs(date).format("DD/MM/YYYY"),
    "dd.MM.YYYY": dayjs(date).format("DD.MM.YYYY"),
    "MM-Ad-VVVY": customFormat(date),
  };

  return formatMapping[format] || dayjs(date).format();
};
function customFormat(date: any) {
  return dayjs(date).format("MM-Ad-YYYY");
}

export const formatTime = (time: string | Dayjs, format: string) => {
  const formatMapping: any = {
    "24-hour time (System setting)": dayjs(time).format("HH:mm"),
    "12-hour time": dayjs(time).format("hh:mm A"),
  };

  return formatMapping[format] || dayjs(time).format(); // Default to system format
};

export const updateBodyStyle = (
  isImage: boolean,
  image?: string,
  bgColor?: string
) => {
  if (isImage) {
    document.body.style.backgroundImage = `url(${image})`;
    document.body.style.backgroundColor = "transparent";
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
  } else {
    document.body.style.backgroundColor = bgColor || "";
  }
};

export const getBackgroundStyle = (option: string) => {
  switch (option) {
    case "Fill":
      return {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };

    case "Center":
      return {
        backgroundSize: "auto",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };

    case "Fit":
      return {
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };

    case "Stretch":
      return {
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };

    case "Tile":
      return {
        backgroundSize: "auto",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
      };

    default:
      return {
        backgroundSize: "auto",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
  }
};

export const applyBackgroundSettings = (
  type: string,
  color: string,
  styles = {},
  image = ""
) => {
  const { style } = document.body;

  if (type === "Color") {
    style.backgroundImage = "none";
    style.backgroundColor = color || "";
  } else {
    const defaultStyles = {
      backgroundImage: `url(${image})`,
      backgroundColor: "transparent",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    Object.assign(style, { ...defaultStyles, ...styles });
  }
};

export const getPeriodDates = (period: string) => {
  switch (period) {
    case "Today":
      return {
        startDate: dayjs().startOf("day"),
        endDate: dayjs().endOf("day"),
      };

    case "Yesterday":
      return {
        startDate: dayjs().subtract(1, "day").startOf("day"),
        endDate: dayjs().subtract(1, "day").endOf("day"),
      };

    case "This Week":
      return {
        startDate: dayjs().startOf("week"),
        endDate: dayjs().endOf("week"),
      };

    case "Last Week":
      return {
        startDate: dayjs().subtract(1, "week").startOf("week"),
        endDate: dayjs().subtract(1, "week").endOf("week"),
      };

    case "This Month":
      return {
        startDate: dayjs().startOf("month"),
        endDate: dayjs().endOf("month"),
      };

    case "Last Month":
      return {
        startDate: dayjs().subtract(1, "month").startOf("month"),
        endDate: dayjs().subtract(1, "month").endOf("month"),
      };

    case "This Year":
      return {
        startDate: dayjs().startOf("year"),
        endDate: dayjs().endOf("year"),
      };

    case "Last Year":
      return {
        startDate: dayjs().subtract(1, "year").startOf("year"),
        endDate: dayjs().subtract(1, "year").endOf("year"),
      };

    default:
      return null;
  }
};
