import { Box, InputAdornment, TextField, Button } from "@mui/material";
import ExportDropdown from "../../../../Components/CustomLogs/ExportDropdown/ExportDropdown";
import SearchIcon from "@material-ui/icons/Search";
import styles from "./styles.module.scss";
const ActionButtons = () => {
  return (
    <Box
      className={styles.search_wrapper}
      // borderBottom={"1px solid #e2e9ef"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      p={"12px"}
      pt={0}
      pb={1}
      pr={"52px"}
      sx={{
        "& .MuiTextField-root": {
          // border: "1px solid red !important",
          // borderRadius: "4px",
          // color: "grey !important",
          // height: "26px",
        },
        "& .MuiOutlinedInput-root": {
          color: "grey !important",
          height: 28,
          pl: 1,
          fieldset: {
            borderColor: "#c8d2dc !important",
          },
          "&:hover fieldset": {
            borderColor: "#c8d2dc !important",
          },
        },
        "& .Mui-focused fieldset": {
          borderWidth: "1px !important",
          borderColor: "#c8d2dc !important",
        },

        "& input": {
          height: 26,
          padding: "0px",
          fontSize: "13px",
          color: "#414b55",
        },

        "& .dx-buttongroup-wrapper": {
          background: "#e8eef4",
          border: "1px solid transparent",
          borderRadius: "4px",
          height: 26,
          width: 85,
          "&:hover": {
            borderColor: "#c8d2dc",
          },

          "& :first-child": {
            borderRight: "1px solid #c8d2dc",
          },
          "& .dx-widget": {
            height: 25,
            color: "#414b55",
            fontSize: 13,
            border: "none",
            "&:hover": {
              background: "transparent",
            },
          },
          "& .dx-dropdownbutton-toggle": {
            padding: "0px !important",
            width: "14px",
            "& .dx-button-content": {
              width: "22px",
            },
          },
          "& .dx-icon-spindown": {
            color: "#414b55",
          },
        },
      }}
    >
      <Box display="flex" alignItems={"center"}>
        <Box
          sx={{
            mr: 2,
            "& img": {
              width: "28px !important",
              height: "27px !important",
            },
            "& .dx-button": {
              border: "1px solid #d9dbdf",
              borderRadius: "4px !important",
            },
            "& .dx-state-active": {
              background: "#e8eef4 !important",
            },
          }}
        >
          <Button
            sx={{
              background: "#e8eef2",
              color: "#414b55",
              fontSize: "13px",
              textTransform: "none",
              p: "4px 0px",
              fontWeight: "normal",
              border: "1px solid transparent",
              height: 26,
              "&:hover": {
                background: "#e8eef2",
                borderColor: "#c8d2dc !important",
              },
            }}
          >
            Clear
          </Button>
        </Box>
        <ExportDropdown />
      </Box>

      <TextField
        sx={{ ml: 2, display: "none" }}
        size="small"
        variant="outlined"
        placeholder="Search"
        onChange={() => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "black", fontSize: "20px" }} />
            </InputAdornment>
          ),
          // endAdornment: (
          //     <InputAdornment
          //         position="end"
          //         style={{ display: showClearIcon }}
          //         onClick={handleClick}
          //     >
          //         <ClearIcon />
          //     </InputAdornment>
          // ),
        }}
      />
    </Box>
  );
};

export default ActionButtons;
