import { Box } from "@mui/material";
import CustomLabel from "../../../Components/customLabel";
import styles from "./styles.module.scss";
import ActionButtons from "./ActionButtons";
import TestGrid from "./TestGrid";
// import { DataGrid } from "devextreme-react";
// import {
//   Column,
//   ColumnChooser,
//   ColumnChooserSearch,
//   ColumnChooserSelection,
//   Pager,
//   Paging,
//   Position,
// } from "devextreme-react/cjs/data-grid";

interface OverviewProps {}

const Overview: React.FC<OverviewProps> = () => {
  // const dataSourceOptions = {
  //   store: {
  //     type: "odata" as const,
  //     version: 2,
  //     url: "https://js.devexpress.com/Demos/DevAV/odata/Products",
  //     key: "Product_ID",
  //   },
  //   select: [
  //     "Product_ID",
  //     "Product_Name",
  //     "Product_Cost",
  //     "Product_Sale_Price",
  //     "Product_Retail_Price",
  //     "Product_Current_Inventory",
  //   ],
  //   filter: ["Product_Current_Inventory", ">", 0],
  // };
  // const searchEditorOptions = { placeholder: 'Search column' };

  // const allowedPageSizes = [8, 12, 20];

  return (
    <Box height={"100%"}>
      <CustomLabel
        className={styles.current_heading}
        label="Current"
        mb="8px"
        display="flex"
        alignItems="center"
        color="#2993d1"
        fontSize={"13px"}
        fontWeight={"bold"}
        paddingBottom={"8px"}
        paddingLeft={"8px"}
        paddingTop={"4px"}
        borderBottom={"1px solid #d2dce5"}
      />
      <ActionButtons />
      <TestGrid />
      {/* <DataGrid
        style={{
          height: "100%",
        }}
        dataSource={dataSourceOptions}
        showBorders={true}
        // height={"calc(100vh - 365px)"}
        height={"100%"}
        remoteOperations={true}
        loadPanel={{ enabled: "auto" }}
        columnChooser={{ enabled: true }}
        elementAttr={{
          id: "data_grid",
          class: styles.data_grid_wrapper,
        }}
        allowColumnResizing
      >
        <LoadPanel
            height={100}
            width={100}
            indicatorSrc="https://js.devexpress.com/Content/data/loadingIcons/rolling.svg"
          />
         <ColumnChooser
          height='340px'
          enabled={true}
          mode={'select'}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />

          <ColumnChooserSearch
            enabled={true}
            editorOptions={searchEditorOptions} />

          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true} />
        </ColumnChooser>
        <Column
          dataField="Product_ID"
          caption="Product Id"
          alignment="left"
          allowResizing
        />
        <Column
          dataField="Product_Name"
          alignment="left"
          allowResizing
        />
        <Column
          dataField="Product_Cost"
          caption="Cost"
          dataType="number"
          format="currency"
          alignment="left"
          allowResizing
        />
        <Column
          dataField="Product_Sale_Price"
          caption="Sale Price"
          dataType="number"
          format="currency"
          alignment="left"
          allowResizing
        />
        <Column
          dataField="Product_Retail_Price"
          caption="Retail Price"
          dataType="number"
          format="currency"
          alignment="left"
          allowResizing
        />
        <Column
          dataField="Product_Current_Inventory"
          caption="Inventory"
          alignment="left"
          allowResizing
        />
        <Paging defaultPageSize={12} />
        <Pager
          visible={true}
          showInfo
          showNavigationButtons
          displayMode="full"
          label="test"
          showPageSizeSelector={true}
        />
      </DataGrid> */}
    </Box>
  );
};

export default Overview;
