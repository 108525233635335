import { RefObject, useRef, useState } from "react";
import { ContextMenuTrigger } from "rctx-contextmenu";
import ContextMenuOptions from "../../Components/contextMenu";
import Folder from "../../Components/folder";
import styles from "./styles.module.scss";
import { TMinimizedState } from "./pagecontainer";
import usePageContainer from "./usePageContainer";
import DataNotFound from "../../Components/datanotfound";
import { featureFlag } from "../../contants/feauture";
import FileModal from "../../Components/fileModal/fileModal";
import SessionStorageUtil from "../../services/session";
import ExportPage from "../ExportPage/ExportPage";
import ImportPage from "../ImportPage/ImportPage";
import { Box, Tooltip } from "@mui/material";
import AccountMenu from "../../Components/AccountMenu/AccountMenu";
import GridPage from "../GridPage/GridPage";
import { AboutModal } from "./aboutModal";
import PersonalPage from "../PersonalPage/PersonalPage";
import dayjs from "dayjs";
import { formatDate, formatTime } from "../../utils";
import Window from "../Window/Window";
import ContextMenu, { ContextMenuTypes } from "devextreme-react/context-menu";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const contextOptions = [{ text: "Remove shortcut" }];

const PageContainer: React.FC = () => {
  const {
    isLoading,
    folders,
    setFolders,
    exportValues,
    setProgress,
    setExportValues,
    importValues,
    setImportValues,
    setStep,
    updateFolders,
    aboutModalVisible,
    hideAboutModal,
    showAboutModal,
    dateFormate,
    timeFormate,
    // desktopIconSize,
    desktopTextColor,
    isWindow,
    showWindowModal,
    hideWindowModal,
  } = usePageContainer();

  const folderRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [loading] = useState(false);

  const [minimized, setMinimized] = useState<TMinimizedState>({});

  const toggleFolder = (id: string) => {
    const selectedFolder = folders.find((item) => item.id === id);
    if (selectedFolder?.isOpen && minimized[selectedFolder.id]) {
      handleRestore(selectedFolder.id);
    }
    if (selectedFolder?.isOpen === false && !!minimized) {
      setMinimized((pre) => ({ ...pre, [selectedFolder.id]: false }));
    }
    setFolders(
      folders.map((folder) =>
        folder.id === id
          ? { ...folder, isOpen: true, isShowDesktop: true }
          : folder
      )
    );
  };

  const handleMinimize = (component: string) => {
    setMinimized((prev) => ({ ...prev, [component]: true }));
  };

  const handleRestore = (component: string) => {
    const currentFolder = folders.find((item) => item.id === component);
    const isFolder =
      currentFolder?.isOpen && !minimized[currentFolder?.id as string];
    // setMinimized((prev) => ({
    //   ...prev,
    //   [component]: isFolder ? false : true,
    // }));
    setTimeout(() => {
      setMinimized((prev) => ({
        ...prev,
        [component]: isFolder ? true : false,
      }));
    }, 10);
  };

  const handleBringToFront = (id: string) => {
    setFolders(
      folders.map((element: any) => {
        return element.id === id
          ? { ...element, isActive: true }
          : { ...element, isActive: false };
      })
    );
  };

  const closeFolder = async (id: string) => {
    const updatedFolder = await folders.map((folder) => {
      return folder.id === id ? { ...folder, isOpen: false } : folder;
    });
    updateFolders(updatedFolder);
    // setFolders(updatedFolder);
    if (exportValues.status !== 1) {
      SessionStorageUtil.remove("exportData");
      setProgress(0);
      setExportValues({});
      setStep(1);
    }
    if (importValues.status !== 1) {
      SessionStorageUtil.remove("importData");
      setProgress(0);
      setImportValues({});
      setStep(1);
    }
  };
  // // it will be remove after demo
  // const isFolderOpen = folders && folders.length > 0 && folders[0].isOpen;
  // useEffect(() => {
  //   if (isFolderOpen) {
  //     setLoading(true);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 3000);
  //   }
  // }, [isFolderOpen]);
  if (isLoading) {
    return <h1>loading.....</h1>;
  }
  if (!folders.length) {
    if (featureFlag.isLocalStates) {
      return <FileModal />;
    }
    return <DataNotFound />;
  }

  // const getUpdatedElement = (updatedElement: any) => {
  //   setElementsArray((prevArray) =>
  //     prevArray.some((el) => el.id === updatedElement.id)
  //       ? prevArray.map((el) =>
  //           el.id === updatedElement.id ? updatedElement : el
  //         )
  //       : [...prevArray, updatedElement]
  //   );
  // };
  // const handleSaveChanges = async (id: string) => {
  //   setSaveLoading(true);
  //   const payloadArry: any[] = [];
  //   elementsArray?.forEach((element) => {
  //     if (element) {
  //       payloadArry.push({ ...element.elementObj, Temperature: element.text });
  //     }
  //   });
  //   const results = await Promise.all(
  //     payloadArry.map((weatherObject) => updateWeather(weatherObject))
  //   );
  //   const updateDataArry = folders.map((item) => {
  //     if (item.id === id) {
  //       return {
  //         ...item,
  //         elements: item.elements.map((itemEl: any) => {
  //           const responseObj: any = results.find(
  //             (item) => item.id === itemEl?.elementObj.id
  //           );
  //           return {
  //             ...itemEl,
  //             text:
  //               itemEl.type === "writeable" && responseObj
  //                 ? responseObj?.Temperature
  //                 : itemEl.text,
  //             elementObj: responseObj ? responseObj : itemEl.elementObj,
  //           };
  //         }),
  //       };
  //     }
  //     return item;
  //   });

  //   const getUpdatedObejct = await updateDataArry.find(
  //     (item) => item.id === id
  //   );
  //   if (!featureFlag.isLocalStates) {
  //     updateData(getUpdatedObejct);
  //   }
  //   setIsChange(false);
  //   setFolders(updateDataArry);
  //   setSaveLoading(false);
  // };
  const openedfolders = folders.filter((item) => item.isOpen);
  const date = dayjs();

  const handleContextClick = async (
    e: ContextMenuTypes.ItemClickEvent,
    folder: any
  ) => {
    if (!e?.itemData?.items) {
      const { text } = e.itemData as any;
      if (text === contextOptions[0].text) {
        const _folders = [...folders];
        const _folderIdx = _folders.findIndex((fold) => fold.id === folder.id);
        _folders[_folderIdx] = {
          ..._folders[_folderIdx],
          isShowDesktop: false,
        };
        updateFolders(_folders);
      }
    }
  };

  return (
    <>
      {!isWindow && (
        <>
          <Box
            bgcolor={"rgba(50, 60, 70, .9)"}
            zIndex={6666}
            p={1.8}
            height={38}
            position={"absolute"}
            top={0}
            right={0}
            display={"flex"}
            borderRadius={"0 0 3px 3px"}
            boxShadow={"0px 1px 2px rgba(10, 20, 30, .5)"}
            mr={"29px"}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "14px",
                borderRight: "1px solid #ccc",
                paddingRight: "14px",
                color: "#ccc",
                fontSize: "13px",
              }}
            >
              {formatDate(date, dateFormate)}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "14px",
                borderRight: "1px solid #ccc",
                paddingRight: "14px",
                color: "#ccc",
                fontSize: "13px",
              }}
            >
              {formatTime(date, timeFormate)}
            </Box>
            <AccountMenu
              handleOpenModal={showAboutModal}
              handleOpenPersonalFolder={() => toggleFolder("13766-33761")}
            />
          </Box>

          <div className={styles.main_container} id="folders_wrapper">
            {/* <div className="desktop_header"></div> */}
            {folders.map((folder, index) => {
              const folderId = folder?.folderName.replaceAll(" ", "_");
              return (
                <div
                  key={folder.id + index}
                  className={`${minimized[folder.id] && styles.drag_class}`}
                  style={{
                    boxSizing: "unset",
                    width: "142px",
                    display: folder.isShowDesktop ? "" : "none",
                  }}
                >
                  <div
                    id={folderId}
                    className="folders_icons_wrap"
                    onClick={() => {
                      toggleFolder(folder.id);
                    }}
                    style={{
                      boxSizing: "unset",
                      display: folder.folderName === "Personal" ? "none" : "",
                    }}
                  >
                    <Tooltip
                      title={folder.folderName}
                      // followCursor
                      placement="right"
                    >
                      <>
                        <div
                          className="folder_icon"
                          style={{
                            // backgroundImage: `url(${
                            //   folder.icon && folder?.icon?.includes("/assets/")
                            //     ? "/assets/icons/large_folder.png"
                            //     : folder.icon
                            // } )`,
                            // width:
                            //   desktopIconSize === "Normal (64×64)"
                            //     ? "64px"
                            //     : "48px",
                            // height:
                            //   desktopIconSize === "Normal (64×64)"
                            //     ? "64px"
                            //     : "48px",
                            // backgroundSize:
                            //   desktopIconSize === "Normal (64×64)"
                            //     ? "64px 64px"
                            //     : "48px 48px",
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              "& svg": {
                                fontSize: "50px",
                                color: "#f3f5f6",
                              },
                            }}
                            children={folder.icon}
                          />
                        </div>
                        <span
                          style={{
                            textShadow: "0em .1em .2em rgba(0,0,0,.8)",
                            color: desktopTextColor,
                            fontSize: "12px",
                            fontWeight: 400,
                            height: 36,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {folder.folderName}
                        </span>
                      </>
                    </Tooltip>
                  </div>

                  {folder.isOpen && (
                    <Folder
                      foldersLength={folders.length}
                      folderCount={folders.filter((item) => item.isOpen).length}
                      onClose={closeFolder}
                      minimized={minimized}
                      handleMinimize={handleMinimize}
                      folder={folder}
                      bringToFront={handleBringToFront}
                      folders={folders}
                      isTitleIcon={folder?.isTitleIcon}
                    >
                      <div
                        className={
                          folder?.isTitleIcon
                            ? styles.content_style2
                            : styles.content_style
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          // e.preventDefault();
                          handleBringToFront(folder.id);
                        }}
                      >
                        {folder.id === "13766-33766" && (
                          <ExportPage
                            handleDone={() => closeFolder(folder.id)}
                          />
                        )}
                        {folder.id === "13766-33768" && (
                          <ImportPage
                            handleDone={() => closeFolder(folder.id)}
                          />
                        )}
                        {folder.id === "13766-33769" && (
                          <GridPage handleDone={() => closeFolder(folder.id)} />
                        )}
                        {folder.id === "13766-33761" && <PersonalPage />}
                      </div>
                    </Folder>
                  )}

                  <ContextMenu
                    cssClass={styles.content_menu_wrapper}
                    dataSource={contextOptions}
                    target={`#${folderId}`}
                    onItemClick={(e) => handleContextClick(e, folder)}
                  />
                </div>
              );
            })}

            {featureFlag.isBottomBar && (
              <div className="bottom_bar">
                <Box
                  sx={{
                    width: 77,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#323c46e6",
                    boxShadow: "0px 1px 2px rgba(10, 20, 30, .5)",
                    border: "1px solid #ffffff1a",
                    borderTopWidth: "0px",
                    // margin: 0 6px 0px 12px;
                    borderRadius: "0px 0px 3px 3px",
                    marginRight: 1,
                    "&:hover": {
                      "& .window_btn": {
                        backgroundPosition: "0px -24px",
                      },
                    },
                  }}
                >
                  <Box
                    className="window_btn"
                    sx={{
                      backgroundSize: "24px 72px",
                      mt: "3px",
                      width: "24px",
                      height: "24px",
                      background: "tranparent",
                      cursor: "pointer",
                      backgroundImage: "url(/assets/icons/window_icon.png)",
                      backgroundPosition: "0px 0px",
                      // border: "1px solid red",
                    }}
                    onClick={showWindowModal}
                  />
                </Box>
                {Object.keys(minimized).map((folder) => {
                  const selectedFolder = folders.find(
                    (item) => item.id === folder
                  );
                  // console.log("selectedFolder", typeof selectedFolder?.icon);

                  return (
                    <div key={folder}>
                      <div
                        style={{
                          display:
                            selectedFolder?.isOpen === false ? "none" : "flex",
                        }}
                        ref={folderRef}
                        className={`minimized_component`}
                        onClick={() => {
                          // disabled minimize feature
                          handleRestore(folder);
                        }}
                      >
                        <ContextMenuTrigger id="unique_identifier">
                          <div
                            className={`taskbar_btn ${
                              loading ? "laoding_br" : ""
                            } ${
                              openedfolders.length >= 2 &&
                              selectedFolder?.isActive
                                ? "is_active"
                                : ""
                            }`}
                            style={{
                              // backgroundImage: `url(${
                              //   selectedFolder?.icon?.includes("/assets/")
                              //     ? "/assets/icons/small_folder.png"
                              //     : selectedFolder?.icon
                              // })`,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                "& svg": {
                                  fontSize: "18px",
                                  color: "#f3f5f6",
                                },
                              }}
                              children={selectedFolder?.icon}
                            />
                          </div>
                          {/* {folder} */}
                        </ContextMenuTrigger>
                      </div>
                      <div className="empty-component" />
                    </div>
                  );
                })}

                <ContextMenuOptions
                  onClose={() => {
                    const folderName = (folderRef.current as any)
                      .querySelector(".menu-trigger")
                      .textContent.trim();
                    const getFolderId: any = folders.find(
                      (item) => item.id === folderName
                    )?.id;

                    if (getFolderId !== undefined) {
                      closeFolder(getFolderId);
                    }
                  }}
                />
              </div>
            )}
          </div>
          <AboutModal popupVisible={aboutModalVisible} hide={hideAboutModal} />
        </>
      )}
      <Window
        popupVisible={isWindow}
        hide={hideWindowModal}
        toggleFolder={toggleFolder}
      />
    </>
  );
};

export default PageContainer;
