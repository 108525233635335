import React from "react";
import styles from "./styles.module.scss";
import { Box } from "@mui/material";

// const Avatar: React.FC<{ icon: string }> = ({ icon }) => (
//     <div className={styles.button_img_container}>
//         <div className={styles.button_img_indicator}></div>
//         <img className={styles.button_img} src={icon} alt="employee" />
//     </div>
// );

// const ButtonDescription: React.FC<{ text: string; description: string }> = ({
//     text,
//     description,
// }) => (
//     <div className={styles.text_container}>
//         <div className={styles.button_title}>{text}</div>
//         <div className={styles.button_row}>{description}</div>
//     </div>
// );

const DropdownItems: React.FC = () => (
  <Box
    sx={{
      background: "#e8eef4",
    }}
    className={styles.export_wrapper}
  >
    Export
    {/* <Avatar icon="https://js.devexpress.com/React/Demos/WidgetsGallery/JSDemos/images/employees/51.png" /> */}
    {/* <ButtonDescription text="Olivia Peyton" description="IT Manager" /> */}
  </Box>
);

export default DropdownItems;
