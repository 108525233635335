import { Box } from "@mui/material";
import OrgForm from "./OrdForm";
import useExportPageHook from "./hooks/useExportPageHook";
import JobDetailsForm from "./JobDetailsForm";
import { memo } from "react";
import JobDetails from "./JobDetails";
import Execute from "./Execute";
import { featureFlag } from "../../contants/feauture";
import { useMainCtx } from "../../redux/store/useMainCtx";

interface ExportPageProps {
  handleDone: () => void;
}

const ExportPage: React.FC<ExportPageProps> = ({ handleDone }) => {
  const {
    step,
    handleOrgFormSubmit,
    isLoading,
    handleBack,
    handleDetailsFormSubmit,
    detailsFormData,
    handleExecute,
  } = useExportPageHook();

  const { exportValues } = useMainCtx((state) => {
    return {
      exportValues: state.exportValues,
    };
  });

  return (
    <Box>
      {step === 1 && (
        <OrgForm
          handleOrgFormSubmit={handleOrgFormSubmit}
          isLoading={isLoading}
          isChanged={featureFlag.isChanged}
          formValues={exportValues}
        />
      )}
      {step === 2 && (
        <JobDetailsForm
          handleBack={handleBack}
          handleDetailsFormSubmit={handleDetailsFormSubmit}
          detailsFormData={detailsFormData}
        />
      )}
      {step === 3 && (
        <JobDetails
          isChanged={featureFlag.isChanged}
          handleBack={handleBack}
          handleExecute={handleExecute}
          detailsFormData={detailsFormData}
          isLoading={isLoading}
          exportFromLabel={
            detailsFormData.department && exportValues.location !== undefined
              ? `${detailsFormData.department} @ ${exportValues.location}`
              : `-`
          }
        />
      )}
      {step === 4 && (
        <Execute
          handleDone={handleDone}
          isChanged={featureFlag.isChanged}
          exportValues={exportValues}
          packageId={exportValues?.packageId || "-"}
          status={exportValues?.status || "-"}
          statusMessage={exportValues?.message || " "}
        />
      )}
    </Box>
  );
};

export default memo(ExportPage);
