import { Box } from "@mui/material";
import { memo } from "react";

import { featureFlag } from "../../contants/feauture";
import { useMainCtx } from "../../redux/store/useMainCtx";
import useImportPageHook from "./hooks/useImportPageHook";
import OrgForm from "./importOrgForm/ImportOrgForm";
import ImportJobDetailsForm from "./importJobDetailsForm/ImportJobDetailsForm";
import ImportJobDetails from "./importJobDetails/ImportJobDetails";
import ImportExecute from "./importExecute/ImportExecute";

interface ImportPageProps {
  handleDone: () => void;
}

const ImportPage: React.FC<ImportPageProps> = ({ handleDone }) => {
  const {
    step,
    handleOrgFormSubmit,
    isLoading,
    handleBack,
    handleDetailsFormSubmit,
    detailsFormData,
    handleExecute,
  } = useImportPageHook();

  const { importValues } = useMainCtx((state) => {
    return {
      importValues: state.importValues,
    };
  });

  return (
    <Box>
      {step === 1 && (
        <OrgForm
          handleOrgFormSubmit={handleOrgFormSubmit}
          isLoading={isLoading}
          isChanged={featureFlag.isChanged}
          formValues={importValues}
        />
      )}
      {step === 2 && (
        <ImportJobDetailsForm
          handleBack={handleBack}
          handleDetailsFormSubmit={handleDetailsFormSubmit}
          detailsFormData={detailsFormData}
        />
      )}

      {step === 3 && (
        <ImportJobDetails
          isChanged={featureFlag.isChanged}
          handleBack={handleBack}
          handleExecute={handleExecute}
          detailsFormData={detailsFormData}
          isLoading={isLoading}
          importFromLabel={
            detailsFormData.department && importValues.location !== undefined
              ? `${detailsFormData.department} @ ${importValues.location}`
              : `-`
          }
        />
      )}

      {step === 4 && (
        <ImportExecute
          handleDone={handleDone}
          isChanged={featureFlag.isChanged}
          importValues={importValues}
          packageId={importValues?.packageId || "-"}
          status={importValues?.status || "-"}
          statusMessage={importValues?.message || " "}
        />
      )}
    </Box>
  );
};

export default memo(ImportPage);
