import useSWR from "swr";
import { getDuration, API_URL } from "../../../utils";
import SessionStorageUtil from "../../../services/session";
import { useMainCtx } from "../../../redux/store/useMainCtx";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import http from "../../../services/http";

const getFetcheStatus = (url: string) => http.apiRequest(url, "GET");

export const useHook = ({ importValues }: any) => {
  const { updateImportValues, setProgress, progress } = useMainCtx((state) => {
    return {
      updateImportValues: state.updateImportValues,
      setProgress: state.setProgress,
      progress: state.progress,
    };
  });

  const [popupVisible, setPopupVisible] = useState(false);
  const importData = SessionStorageUtil.get("importData", true) as any;

  const { data, isValidating: isLoading } = useSWR(
    importData?.packageId || importValues.status === 1
      ? `${API_URL.GET_IMPORT_STATUS}${importData?.packageId}`
      : null,
    getFetcheStatus,
    {
      refreshInterval: 30000,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (importData) {
      updateImportValues(importData);
    }
    if (data) {
      updateImportValues({
        message: data?.output?.message,
        status: data?.output?.status,
        packageId: data?.id,
        startTime: data
          ? dayjs(data["created-time"]).format("ddd, DD MMM YYYY HH:mm:ss")
          : "-",
        endTime: dayjs().format("ddd, DD MMM YYYY HH:mm:ss"),
        duration:
          data?.output?.status !== 1 ? getDuration(data["created-time"]) : null,
      });
      if (data?.output?.status !== 1) {
        setProgress(100);
        SessionStorageUtil.remove("importData");
      } else if (data.output.status === 1 && progress.toFixed() === "90") {
        setProgress(90);
        return;
      } else {
        setProgress(Math.min(progress + 0.2, 100));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const hide = useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);

  const showPopup = useCallback(() => {
    setPopupVisible(true);
  }, [setPopupVisible]);

  return { isLoading, hide, popupVisible, showPopup };
};
