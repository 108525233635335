import { Box, Button, Checkbox } from "@mui/material";
import CustomLabel from "../../../Components/customLabel";
import CustomSelect from "../../../Components/CustomSelect";
import { Check } from "@material-ui/icons";
import useDisplayPreferences from "./useDisplayPreferences";
import ColorPicker from "./ColorPicker/ColorPicker";
import UploadImageModal from "./UploadImageModal/UploadImageModal";

const DisplayPreferences = () => {
  const {
    dateFormate,
    timeFormate,
    // menuStyle,
    iconSize,
    isCustomizeBg,
    backgroundType,
    backgroundSize,
    setBackgroundSize,
    setBackgroundType,
    setIsCustomizeBg,
    setIconSize,
    // setMenuStyle,
    setTimeDateFormat,
    setDateFormat,
    handleApply,
    desktopBgColor,
    hideUploadImageModal,
    showUploadImageModal,
    uploadImageModalVisible,
    bgImage,
    handleBgColorChange,
    handleTextColorChange,
    desktopTextColor,
  } = useDisplayPreferences();

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Box
        pt="16px"
        px={"16px"}
        pb={0}
        sx={{
          height: "calc(100vh - 372px)",
        }}
      >
        {/* Date Format */}
        <CustomLabel
          label="Date & Time Format"
          mb="8px"
          height={22}
          display="flex"
          alignItems="center"
          color="#2993d1"
          fontSize={"16px"}
          paddingBottom={"6px"}
          paddingLeft={"8px"}
          borderBottom={"1px dashed #d2dce5"}
        />

        <Box width={"100%"} display={"flex"} mb={2}>
          <Box width={"35%"} pl={1}>
            <CustomLabel
              label="Date Format"
              mb="8px"
              height={28}
              display="flex"
              alignItems="center"
            />
            <CustomLabel
              label="Time format"
              mb="8px"
              height={28}
              display="flex"
              alignItems="center"
            />
          </Box>
          <Box width={"65%"}>
            <Box
              mb={"8px"}
              width={270}
              sx={{
                "& .MuiSelect-select": {
                  height: "22px !important",
                },
              }}
            >
              <CustomSelect
                props={{
                  fullWidth: true,
                  name: "dateFormate",
                  value: dateFormate,
                  onChange: (e) => setDateFormat(e.target.value),
                }}
                isStringArray
                options={[
                  "YYYY-MM-dd (System setting)",
                  "MMY/MM/dd",
                  "YYYY.MM.dd",
                  "dd-MM-YYYY",
                  "dd/MM/YYYY",
                  "dd.MM.YYYY",
                  "MM-Ad-VVVY",
                ]}
              />
            </Box>
            <Box
              mb={"8px"}
              width={270}
              sx={{
                "& .MuiSelect-select": {
                  height: "22px !important",
                },
              }}
            >
              <CustomSelect
                props={{
                  fullWidth: true,
                  name: "timeFormat",
                  value: timeFormate,
                  onChange: (e) => setTimeDateFormat(e.target.value),
                  // value: formik.values.language,
                  // onChange: formik.handleChange,
                  // error:
                  //   formik.touched.language && Boolean(formik.errors.language),
                  // helperText: formik.touched.language && (
                  //   <Warning
                  //     sx={{ fontSize: 20, mt: "4px" }}
                  //     className="error_icon"
                  //   />
                  // ),
                }}
                isStringArray
                options={["24-hour time (System setting)", "12-hour time"]}
              />
            </Box>
          </Box>
        </Box>
        <CustomLabel
          label="Desktop"
          mb="8px"
          height={22}
          display="flex"
          alignItems="center"
          color="#2993d1"
          fontSize={"16px"}
          paddingBottom={"6px"}
          paddingLeft={"8px"}
          borderBottom={"1px dashed #d2dce5"}
        />

        <Box width={"100%"} display={"flex"}>
          <Box width={"35%"} pl={1}>
            <CustomLabel
              label="Desktop icon size"
              mb="8px"
              height={28}
              display="flex"
              alignItems="center"
            />
            <CustomLabel
              label="Text color"
              mb="8px"
              height={28}
              display="flex"
              alignItems="center"
            />
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <Checkbox
                sx={{
                  p: 0,
                  mr: 1,
                  border: "1px solid #d7e0e9",
                  borderRadius: "4px",
                  height: "20px",
                  width: "20px",
                  "& .MuiSvgIcon-fontSizeMedium": {
                    display: !isCustomizeBg ? "none" : "",
                  },
                }}
                checked={isCustomizeBg}
                checkedIcon={
                  <Check sx={{ fontSize: "20px", color: "#2993d1" }} />
                }
                onChange={() => setIsCustomizeBg(!isCustomizeBg)}
              />
              <CustomLabel
                label="Customize background"
                height={28}
                display="flex"
                alignItems="center"
              />
            </Box>
            <Box
              sx={{
                opacity: !isCustomizeBg ? 0.3 : "",
              }}
            >
              {backgroundType !== "Image" ? (
                <Box
                  bgcolor={desktopBgColor}
                  width={178}
                  height={114}
                  border="1px solid #d7e0e9"
                />
              ) : (
                <img
                  src={bgImage}
                  alt=""
                  width={178}
                  height={114}
                  style={{
                    border: "1px solid #d7e0e9",
                  }}
                />
              )}
            </Box>
          </Box>
          <Box width={"65%"}>
            <Box
              mb={"8px"}
              width={270}
              sx={{
                "& .MuiSelect-select": {
                  height: "22px !important",
                },
              }}
            >
              <CustomSelect
                props={{
                  fullWidth: true,
                  name: "iconSize",
                  value: iconSize,
                  onChange: (e) => setIconSize(e.target.value),
                }}
                isStringArray
                options={["Normal (64×64)", "Classic (48x84)"]}
              />
            </Box>

            <Box
              mb={"8px"}
              width={270}
              sx={
                {
                  // "& .MuiSelect-select": {
                  //   height: "22px !important",
                  // },
                }
              }
            >
              <ColorPicker
                disabled={false}
                color={desktopTextColor}
                handleBgColorChange={handleTextColorChange}
              />
            </Box>

            <Box
              mb={"14px"}
              width={270}
              height={22}
              alignItems={"center"}
              display={"flex"}
              sx={{
                "& .MuiSelect-select": {
                  height: "22px !important",
                },
              }}
            />

            {/* Image section  */}
            <Box
              mb={backgroundType !== "Image" ? "8px" : "22px"}
              width={270}
              sx={{
                "& .MuiSelect-select": {
                  height: "22px !important",
                },
              }}
            >
              <CustomSelect
                props={{
                  fullWidth: true,
                  name: "backgroundType",
                  value: backgroundType,
                  onChange: (e) => setBackgroundType(e.target.value),
                }}
                isStringArray
                disabled={!isCustomizeBg}
                options={["Image", "Color"]}
              />
            </Box>

            {backgroundType !== "Image" ? (
              <ColorPicker
                disabled={!isCustomizeBg}
                color={desktopBgColor}
                handleBgColorChange={handleBgColorChange}
              />
            ) : (
              <>
                <Box mb={1}>
                  <Button
                    disabled={!isCustomizeBg}
                    sx={{
                      mt: 0,
                      textTransform: "none",
                      background: "#e6eff2",
                      color: "#414B55",
                      padding: "3px 16px",
                      fontSize: "13px",
                      fontWeight: 400,
                      "&:hover": {
                        background: "#e6eff2",
                      },
                    }}
                    onClick={showUploadImageModal}
                  >
                    Select Image
                  </Button>
                </Box>
                <Box
                  mb={"8px"}
                  width={270}
                  sx={{
                    "& .MuiSelect-select": {
                      height: "22px !important",
                    },
                  }}
                >
                  <CustomSelect
                    props={{
                      fullWidth: true,
                      name: "backgroundSize",
                      value: backgroundSize,
                      onChange: (e) => setBackgroundSize(e.target.value),
                    }}
                    disabled={!isCustomizeBg}
                    isStringArray
                    options={["Fill", "Center", "Fit", "Stretch", "Tile"]}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {/* bottom bar  */}
      <Box
        height={29}
        sx={{
          borderTop: "1px solid #c6d4e080",
          padding: "9px 16px 9px 0",
          boxSizing: "content-box",
        }}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Box
          display="flex"
          justifyContent={"end"}
          sx={{
            "& .Mui-disabled": {
              borderColor: "#0000001f !important",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "#c6d4e066",
              borderRadius: "100px",
              border: "1px solid #c6d4e000",
              marginRight: "8px",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              color: "#414b55",
              textTransform: "none",
              width: 85,
              boxShadow:
                "0px 3px 1px -3px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.12)",
              "&:hover": {
                backgroundColor: "rgba(198, 212, 224, 0.4)",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
              },
            }}
            onClick={() => {}}
            disabled={false}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#2993d1",
              border: "1px solid #2993d1",
              color: "#fff",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              borderRadius: "100px",
              textTransform: "none",
              minWidth: "92px",
              marginRight: "8px",
              width: 120,
              "&:hover": {
                backgroundColor: "#2993d1",
              },
            }}
            onClick={handleApply}
            disabled={false}
          >
            Apply
          </Button>
        </Box>
      </Box>
      <UploadImageModal
        hide={hideUploadImageModal}
        popupVisible={uploadImageModalVisible}
      />
    </Box>
  );
};
export default DisplayPreferences;
