import http from "../../../services/http";
import { API_URL } from "../../../utils";
import { useState } from "react";

const useViewLogsPopup = () => {
  const [isLoading, setIsLoading] = useState<any>({});
  const [isBulkLoading, setIsBulkLoading] = useState<any>(false);
  const [isViewLoading, setIsViewLoading] = useState<any>(false);

  const handleLoading = (index: any, isLoading: any) => {
    setIsLoading((prev: any) => ({ ...prev, [index]: isLoading }));
  };

  const handleDownload = async (fileUrl: string, index: any) => {
    handleLoading(index, true);
    try {
      await http.downloadFile(`${API_URL.BASE_URL}/${fileUrl}`);
      handleLoading(index, false);
    } catch (error) {
    } finally {
      handleLoading(index, false);
    }
  };

  const handleView = async (fileUrl: string, _index: any) => {
    setIsViewLoading(true);
    try {
      await http.previewFile(`${API_URL.BASE_URL}/${fileUrl}`);
      setIsViewLoading(false);
    } catch (error) {
    } finally {
      setIsViewLoading(false);
    }
  };

  //   function MultiApiComponent() {
  //   const [data, setData] = useState([]);
  //   const [loading, setLoading] = useState(false);
  //   const [error, setError] = useState(null);

  //   const apiUrls = [
  //     '/api/endpoint1',
  //     '/api/endpoint2',
  //     '/api/endpoint3',
  //     // Add more endpoints here up to 10
  //   ];

  //   const fetchAllData = async () => {
  //     setLoading(true);
  //     setError(null);

  //     try {
  //       const results = await Promise.all(apiUrls.map(url => fetcher(url)));
  //       setData(results);
  //     } catch (err) {
  //       setError(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (loading) return <div>Loading...</div>;
  //   if (error) return <div>Error loading data</div>;

  //   return (
  //     <div>
  //       <button onClick={fetchAllData}>Fetch All APIs</button>
  //       <h1>Data from Multiple APIs:</h1>
  //       {data.map((result, index) => (
  //         <p key={index}>Data {index + 1}: {JSON.stringify(result)}</p>
  //       ))}
  //     </div>
  //   );
  // }

  const handleDownloadAll = async (urls: string[]) => {
    setIsBulkLoading(true);

    const fullUrls = urls.map((item) => `${API_URL.BASE_URL}/${item}`);
    try {
      await http.downloadAllFiles(fullUrls);
      setIsBulkLoading(false);
    } catch (error) {
      console.log("error from bulk download files", error);
      setIsBulkLoading(false);
    } finally {
      setIsBulkLoading(false);
    }
  };

  return {
    isLoading,
    handleDownload,
    handleView,
    handleDownloadAll,
    isBulkLoading,
    isViewLoading,
  };
};

export default useViewLogsPopup;
