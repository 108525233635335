import { useState } from "react";
import { useMainCtx } from "../../../redux/store/useMainCtx";
import { mutate } from "swr";

import { toast } from "react-toastify";
import SessionStorageUtil from "../../../services/session";
import dayjs from "dayjs";
import { API_URL } from "../../../utils";
import { featureFlag } from "../../../contants/feauture";
import http from "../../../services/http";

const useImportPageHook = () => {
  const importData = SessionStorageUtil.get("importData", true) as any;
  const { setImportValues, importValues } = useMainCtx((state) => {
    return {
      setImportValues: state.setImportValues,
      importValues: state.importValues,
    };
  });

  const postImport = (url: string, payload: any) =>
    http.apiRequest(url, "POST", {
      ...payload,
    });

  const [step, setStep] = useState(importData?.packageId ? 4 : 1);

  const [detailsFormData, setDetailsFormData] = useState({
    name: "",
    description: "",
    department: "",
    exportScript: "Select Script",
  });
  const [isLoading, setLoading] = useState(false);

  const handleNext = () => setStep((prev) => prev + 1);
  const handleBack = () => setStep((prev) => prev - 1);
  const handleOrgFormSubmit = (value: any) => {
    setImportValues(value);
    setImportValues({ ...importValues, ...value });
    handleNext();
  };

  const handleDetailsFormSubmit = (value: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDetailsFormData(value);
      setImportValues({ ...importValues, ...value });
      handleNext();
    }, 500);
  };

  const handleExecute = async () => {
    setLoading(true);

    const previousPayload = {
      name: importValues.name,
      description: importValues.description,
      connection: {
        client: importValues.clientId,
        secret: importValues.secret,
        region: importValues.region,
      },
      params: {
        "parallel-executions": "25",
        "fault-tolerant-mode": true,
        enable_clusters: "true",
        "rerun-bad-script-type": "cluster",
        "rerun-bad-script": "true",
        "rerun-bad-script-count": "10",
        "build-display-name": "",
        "job-name": "",
        lob: importValues.department,
        export: {
          "genesys_cloud.location": importValues.location,
        },
        import: {
          "genesys_cloud.location": importValues.location,
        },
      },

      payload: {
        "resource-id": importValues.exportScript,
        source: "Export",
      },
      "callback-url": "",
    };

    if (!featureFlag.importExecuteAPI) {
      setTimeout(() => {
        setLoading(false);
        handleNext();
      }, 1000);
    }

    try {
      const response = await mutate(
        featureFlag.importExecuteAPI ? `${API_URL.POST_IMPORT_EXECUTE}` : null,
        postImport(`${API_URL.POST_IMPORT_EXECUTE}`, previousPayload),
        {
          optimisticData: previousPayload,
          rollbackOnError: true,
        }
      );
      setLoading(false);
      SessionStorageUtil.set(
        "importData",
        {
          packageId: response?.id,
          name: importValues?.name,
          description: importValues?.description,
          department: importValues?.department,
          location: importValues?.location,
          startTime:
            response &&
            dayjs(response["created-time"]).format("ddd, DD MMM YYYY HH:mm:ss"),
          status: response?.output.status,
          message: response?.output?.message,
          output: response?.output,
        },
        true
      );

      setImportValues({
        ...importValues,
        status: response?.output?.status,
        message: response?.output?.message,
        packageId: response?.id,
        startTime:
          response &&
          dayjs(response["created-time"]).format("ddd, DD MMM YYYY HH:mm:ss"),
      });
      toast.success("Successfully start execution", { autoClose: 2000 });
      handleNext();
    } catch (error) {
      console.log("error from execute post api", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    step,
    isLoading: isLoading,
    handleBack,
    handleNext,
    handleOrgFormSubmit,
    // details form
    detailsFormData,
    handleDetailsFormSubmit,
    handleExecute,
  };
};

export default useImportPageHook;
