/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import DropDownButton, {
  DropDownButtonTypes,
} from "devextreme-react/drop-down-button";
import notify from "devextreme/ui/notify";
import DropdownItems from "./DropdownItems";
import styles from "./styles.module.scss";

const ExportDropdown = () => {
  const onButtonClick = useCallback((e: any) => {
    notify(
      `Go to ${
        e.element.querySelector(".button-title")?.textContent
      }'s profile`,
      "success",
      600
    );
  }, []);

  const onItemClick = useCallback((e: DropDownButtonTypes.ItemClickEvent) => {
    notify(e.itemData.name || e.itemData, "success", 600);
  }, []);

  return (
    <DropDownButton
      id="custom-template"
      splitButton={true}
      useSelectMode={false}
      items={[
        { id: 1, name: "CSV" },
        {
          id: 4,
          name: "HTML",
        },
      ]}
      displayExpr="name"
      keyExpr="id"
      onButtonClick={onButtonClick}
      onItemClick={onItemClick}
      render={DropdownItems}
      elementAttr={{
        id: "group_btn_wrapper",
        class: styles.group_btn_wrapper,
      }}
    ></DropDownButton>
  );
};

export default ExportDropdown;
