import "./app.scss";
// import PageContainer from "./Pages/PageContainer";
import "./services/amplifyConfig";
import { useAuthenticator } from "@aws-amplify/ui-react";
import AmplifyAuth from "./Pages/Login/AmplifyAuth/AmplifyAuth";
import PageContainer from "./Pages/PageContainer";
import { useMainCtx } from "./redux/store/useMainCtx";
import { useEffect } from "react";
import { applyBackgroundSettings } from "./utils";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

const App: React.FC = () => {
  library.add(fas);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { bgImage, desktopBgColor } = useMainCtx((state) => {
    return {
      bgImage: state.bgImage,
      desktopBgColor: state.desktopBgColor,
    };
  });
  useEffect(() => {
    applyBackgroundSettings("Color", desktopBgColor, {}, bgImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useAuthenticator((context) => {
    return [context.authStatus];
  });

  if (authStatus !== "authenticated") {
    return <AmplifyAuth />;
  } else {
    return <PageContainer />;
  }
};

export default App;
