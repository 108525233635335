import { Amplify } from "aws-amplify";
import config from "../amplifyconfiguration.json";
import http from "./http";

Amplify.configure(config, {
  API: {
    REST: {
      headers: async () => {
        const token = (await http.getAuthenticationToken()) as string;
        return { Authorization: `Bearer ${token.toString()}` };
      },
    },
  },
});
