import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataState {
  data: any;
}

const initialState: DataState = {
  data: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { updateData } = dataSlice.actions;
export default dataSlice.reducer;
