import { Box, Typography } from "@mui/material";

interface DefaultWallaperProps {
  setSelectedImage: any;
  selectedImage: {
    title: string;
    url: string;
  } | null;
}

const DefaultWallaper: React.FC<DefaultWallaperProps> = ({
  setSelectedImage,
  selectedImage,
}) => {
  const wallpapers = [
    {
      title: "Wallpaper1",
      url: "https://img.freepik.com/free-vector/data-visualization-dynamic-wave-pattern-vector_53876-59128.jpg?t=st=1731671937~exp=1731675537~hmac=1c0cfc63ecc680b6d6a5b9aae4a54b8241ee5ba1422a6e817b1178ff70a5b815&w=1800",
    },
    {
      title: "Wallpaper2",
      url: "https://4kwallpapers.com/images/walls/thumbs_2t/18688.jpg",
    },
    {
      title: "Wallpaper3",
      url: "https://4kwallpapers.com/images/walls/thumbs_2t/8957.png",
    },
    {
      title: "Wallpaper4",
      url: "https://4kwallpapers.com/images/walls/thumbs_uwide/19743.png",
    },
    {
      title: "Wallpaper5",
      url: "https://4kwallpapers.com/images/walls/thumbs_uwide/19716.jpg",
    },
    {
      title: "Wallpaper6",
      url: "https://4kwallpapers.com/images/walls/thumbs_uwide/19802.jpg",
    },
    {
      title: "Wallpaper7",
      url: "https://4kwallpapers.com/images/walls/thumbs_2t/16996.jpg",
    },
    {
      title: "Wallpaper8",
      url: "https://img.freepik.com/free-photo/lake-mountains_1204-502.jpg?t=st=1731671470~exp=1731675070~hmac=4b051e04a33b771d41dee8168dd23cf4c7a512c288271eb46b00fb42b689b3f9&w=1800",
    },
    {
      title: "Wallpaper9",
      url: "https://img.freepik.com/free-photo/beautiful-shot-colorful-autumn-forest-full-different-kinds-plants_181624-12425.jpg?t=st=1731671694~exp=1731675294~hmac=07a8a764d1d6e0dbf1124e5ee1bc8d8df5121174ce475ca7033e245bc2f25a3c&w=1800",
    },
    {
      title: "Wallpaper10",
      url: "https://4kwallpapers.com/images/walls/thumbs_2t/17019.jpg",
    },
  ];

  const handleSelect = (item: any) => {
    setSelectedImage(item);
  };

  return (
    <Box
      display={"flex"}
      flexWrap={"wrap"}
      gap={"10px"}
      p={"12px"}
      height={"100%"}
      overflow={"auto"}
    >
      {wallpapers.map((item) => (
        <Box
          p="6px"
          textAlign={"center"}
          borderRadius={"3px"}
          pt={"48px"}
          bgcolor={item.title === selectedImage?.title ? "#3c77ff1a" : ""}
          border={`1px solid  ${
            item.title === selectedImage?.title ? "#3c77ff4d" : "transparent"
          }`}
          sx={{
            "&:hover": {
              bgcolor: "#3c77ff1a",
            },
          }}
          onClick={() => {
            handleSelect(item);
          }}
        >
          <img
            src={item.url}
            alt=""
            width={140}
            height={80}
            style={{
              boxShadow: "0 1px 3px rgba(0,0,0,0.5)",
            }}
          />
          <Typography sx={{ fontSize: "13px", mt: 0.6, color: "#404b54" }}>
            {item.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default DefaultWallaper;
