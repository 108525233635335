import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Warning } from "@material-ui/icons";
import { useHook } from "./useHook";
import TopHeading from "../../Components/TopHeading";
import Loading from "../../../Components/Loading/Loading";
import CustomLabel from "../../Components/customLabel";
import CustomTextField from "../../Components/CustomTextField";
import CustomSelect from "../../Components/CustomSelect";

interface ImportJobDetailsFormProps {
  handleDetailsFormSubmit: (value: any) => void;
  detailsFormData: {
    name: string;
    description: string;
    department: string;
    exportScript: string;
  };
  handleBack: () => void;
}

const ImportJobDetailsForm: React.FC<ImportJobDetailsFormProps> = ({
  handleDetailsFormSubmit,
  detailsFormData,
  handleBack,
}) => {
  const { isLoading, exportScriptList } = useHook();
  const validationSchema = yup.object({
    name: yup.string().required("required"),
    description: yup.string().required("required"),
    department: yup.string().required("required"),
    exportScript: yup
      .string()
      .test("region", "required", (value) => value !== "Select Script")
      .required("required"),
  });

  const formik = useFormik({
    initialValues: detailsFormData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleDetailsFormSubmit(values);
    },
  });

  const { name, department, description, exportScript } = formik.values;
  const isDisabled =
    exportScript === "Select Script" || !description || !department || !name;

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        formik.handleSubmit(e);
      }}
    >
      <TopHeading title="Enter details about the import Job" />
      <Box sx={{ padding: "24px 40px 24px" }} height={"440px"}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <CustomLabel
              label="Enter details about your import Job and select the appropriate export script you
                    want to execute."
              mb="24px"
            />

            <Box width={"100%"} display={"flex"}>
              <Box width={"25%"}>
                <CustomLabel
                  label="Name:"
                  mb="8px"
                  height={28}
                  display="flex"
                  alignItems="center"
                />
                <CustomLabel
                  label="Description:"
                  mb="4px"
                  height={75}
                  display="flex"
                  alignItems="start"
                  pt={"4px"}
                />
                <CustomLabel
                  label="Department:"
                  mb="8px"
                  height={28}
                  display="flex"
                  alignItems="center"
                />
                <CustomLabel
                  label="Export Script:"
                  mb="8px"
                  height={28}
                  display="flex"
                  alignItems="center"
                />
              </Box>
              <Box width={"75%"}>
                <Box mb={"8px"} width={270}>
                  <CustomTextField
                    props={{
                      placeholder: "Job Name",
                      inputProps: { maxLength: 32 },
                      name: "name",
                      fullWidth: true,
                      value: formik.values.name,
                      onChange: formik.handleChange,
                      error: formik.touched.name && Boolean(formik.errors.name),
                      helperText: formik.touched.name && (
                        <Warning
                          sx={{ fontSize: 20, mt: "4px" }}
                          className="error_icon"
                        />
                      ),
                    }}
                  />
                </Box>
                <Box
                  mb={"8px"}
                  width={270}
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "3px 11px",
                      width: "270px",
                    },
                  }}
                >
                  <CustomTextField
                    props={{
                      placeholder: "Daily export from prod...",
                      multiline: true,
                      fullWidth: true,
                      rows: 3,
                      inputProps: { maxLength: 128 },
                      name: "description",
                      value: formik.values.description,
                      onChange: formik.handleChange,
                      error:
                        formik.touched.description &&
                        Boolean(formik.errors.description),
                      helperText: formik.touched.description && (
                        <Warning
                          sx={{ fontSize: 20, mt: "4px" }}
                          className="error_icon"
                        />
                      ),
                    }}
                  />
                </Box>
                <Box mb={"8px"} width={270}>
                  <CustomTextField
                    props={{
                      name: "department",
                      fullWidth: true,
                      placeholder: "ServiceLine",
                      inputProps: { maxLength: 16 },
                      value: formik.values.department.replace(" ", "-"),
                      onChange: formik.handleChange,
                      error:
                        formik.touched.department &&
                        Boolean(formik.errors.department),
                      helperText: formik.touched.department && (
                        <Warning
                          sx={{ fontSize: 20, mt: "4px" }}
                          className="error_icon"
                        />
                      ),
                    }}
                  />
                </Box>

                <Box mb={"8px"} width={336}>
                  <CustomSelect
                    props={{
                      fullWidth: true,
                      name: "exportScript",
                      value: formik.values.exportScript,
                      onChange: formik.handleChange,
                      error:
                        formik.touched.exportScript &&
                        Boolean(formik.errors.exportScript),
                      helperText: formik.touched.exportScript && (
                        <Warning
                          sx={{ fontSize: 20, mt: "4px" }}
                          className="error_icon"
                        />
                      ),
                    }}
                    isStringArray
                    options={["Select Script", ...exportScriptList]}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {/* Bottom buttons */}
      <Box
        height={29}
        sx={{
          borderTop: "1px solid #c6d4e080",
          padding: "9px 16px 9px 0",
          boxSizing: "content-box",
        }}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Box
          display="flex"
          justifyContent={"end"}
          sx={{
            "& .Mui-disabled": {
              borderColor: "#0000001f !important",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "#c6d4e066",
              borderRadius: "100px",
              border: "1px solid #c6d4e000",
              marginRight: "8px",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              color: "#414b55",
              textTransform: "none",
              width: 85,
              boxShadow:
                "0px 3px 1px -3px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.12)",
              "&:hover": {
                backgroundColor: "rgba(198, 212, 224, 0.4)",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
              },
            }}
            onClick={handleBack}
            disabled={isLoading}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#2993d1",
              border: "1px solid #2993d1",
              color: "#fff",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              borderRadius: "100px",
              textTransform: "none",
              minWidth: "92px",
              marginRight: "8px",
              width: 120,
              "&:hover": {
                backgroundColor: "#2993d1",
              },
            }}
            type="submit"
            disabled={isLoading || isDisabled}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ImportJobDetailsForm;
