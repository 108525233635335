import { memo, useCallback, useState } from "react";
import { Popup } from "devextreme-react/popup";
import { Box, Button, Typography } from "@mui/material";
import styles from "./styles.module.scss";

export const ConfirmPopup = ({
  popupVisible,
  id,
  handleCancel,
  handleOk,
  pWidth = 522,
  message,
}: any) => {
  const [width, setWidth] = useState(pWidth);
  const [height, setHeight] = useState("auto");

  const handleResizeEnd = useCallback(
    ({ height, width }: any) => {
      setHeight(height);
      setWidth(width);
    },
    [setWidth, setHeight]
  );

  return (
    <Box className={styles.confirm_popup_wrapper}>
      <Popup
        id={id}
        wrapperAttr={{
          id: id,
          class: styles.confirm_popup_wrapper,
        }}
        width={width}
        height={height}
        visible={popupVisible}
        onHiding={handleCancel}
        hideOnOutsideClick={true}
        showCloseButton={false}
        resizeEnabled={false}
        dragEnabled={false}
        // title="View Logs"
        enableBodyScroll={false}
        deferRendering={true}
        onResizeEnd={handleResizeEnd}
        onVisibleChange={(value: any) => console.log("value", value)}
      >
        <Typography variant="h6" sx={{ color: "#414b55", fontSize: "13px" }}>
          {message}
        </Typography>

        <Box
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
          mt={"18px"}
        >
          <Button
            variant="contained"
            sx={{
              background: "#c6d4e066",
              borderRadius: "100px",
              border: "1px solid #c6d4e000",
              marginRight: "8px",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              color: "#414b55",
              textTransform: "none",
              width: 85,
              boxShadow:
                "0px 3px 1px -3px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.12)",
              "&:active": {
                borderColor: "transparent !important",
              },
              "&:hover": {
                backgroundColor: "rgba(198, 212, 224, 0.4)",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
              },
            }}
            onClick={handleCancel}
            disabled={false}
          >
            No
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#2993d1",
              border: "1px solid #2993d1",
              color: "#fff",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              borderRadius: "100px",
              textTransform: "none",
              minWidth: "92px",
              width: 120,
              borderColor: "transparent !important",
              "& .Mui-disabled": {
                borderColor: "transparent !important",
              },

              "&:hover": {
                backgroundColor: "#2993d1",
              },
            }}
            type="submit"
            disabled={false}
            onClick={handleOk}
          >
            Yes
          </Button>
        </Box>
      </Popup>
    </Box>
  );
};

export default memo(ConfirmPopup);
