import { Box, Button } from "@mui/material";
import { Popup, TabPanel } from "devextreme-react";
import styles from "./styles.module.scss";
import { useCallback, useState } from "react";
import { ToolbarItem } from "devextreme-react/cjs/popup";
import DefaultWallaper from "./DefaultWallpaper/DefaultWallaper";
import { useMainCtx } from "../../../../../redux/store/useMainCtx";
import MyImages from "./MyImages/MyImages";

interface UploadImageModalProps {
  popupVisible: boolean;
  hide: any;
}

const UploadImageModal: React.FC<UploadImageModalProps> = ({
  popupVisible,
  hide,
}) => {
  const { setGlobalBgImage } = useMainCtx((state) => {
    return { setGlobalBgImage: state.setGlobalBgImage };
  });
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [width, setWidth] = useState(700);
  const [height, setHeight] = useState(580);
  const [selectedImage, setSelectedImage] = useState<{
    title: string;
    url: string;
  } | null>(null);

  const handleResizeEnd = useCallback(
    ({ height, width }: any) => {
      setHeight(height);
      setWidth(width);
    },
    [setWidth, setHeight]
  );

  const handelSelectImage = () => {
    if (selectedImage) {
      setGlobalBgImage(selectedImage.url);
    }
  };

  const uploadImageTabsData = [
    {
      title: "My Images",
      icon: "/assets/icons/my_images_icon.png",
    },
    {
      title: "Default Wallpaper",
      icon: "/assets/icons/default_wallpaper_icon.png",
    },
  ];

  return (
    <Box>
      <Popup
        id="upload_image_popup"
        wrapperAttr={{
          id: "upload_image_popup",
          class: styles.upload_image_wrapper,
        }}
        width={width}
        height={height}
        visible={popupVisible}
        onHiding={hide}
        hideOnOutsideClick={true}
        showCloseButton={true}
        resizeEnabled={false}
        dragEnabled
        title="Select Image"
        enableBodyScroll={false}
        deferRendering={true}
        onResizeEnd={handleResizeEnd}
        onVisibleChange={(value: any) => console.log("value", value)}
      >
        <Box height={"100%"}>
          <TabPanel
            tabsPosition={"left"}
            stylingMode={"secondary"}
            iconPosition={"start"}
            width="100%"
            height={"100%"}
            elementAttr={{
              style: "height:100%;",
            }}
            selectedIndex={selectedIndex}
            dataSource={uploadImageTabsData}
            onSelectedIndexChange={(value) => setSelectedIndex(value)}
            itemComponent={() => {
              if (selectedIndex === 0) {
                return (
                  <MyImages
                    setSelectedImage={setSelectedImage}
                    selectedImage={selectedImage}
                  />
                );
              }
              if (selectedIndex === 1) {
                return (
                  <DefaultWallaper
                    setSelectedImage={setSelectedImage}
                    selectedImage={selectedImage}
                  />
                );
              }
            }}
          />
        </Box>
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          children={
            <Box>
              <Button
                variant="contained"
                sx={{
                  background: "#c6d4e066",
                  borderRadius: "100px",
                  border: "1px solid #c6d4e000",
                  marginRight: "8px",
                  fontFamily: "Verdana,Arial,sans-serif",
                  fontSize: "13px",
                  padding: "0px 13px",
                  color: "#414b55",
                  textTransform: "none",
                  width: 85,
                  boxShadow:
                    "0px 3px 1px -3px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.12)",
                  "&:active": {
                    borderColor: "transparent !important",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(198, 212, 224, 0.4)",
                    boxShadow:
                      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                  },
                }}
                onClick={hide}
                disabled={false}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "#2993d1",
                  border: "1px solid #2993d1",
                  color: "#fff",
                  fontFamily: "Verdana,Arial,sans-serif",
                  fontSize: "13px",
                  padding: "0px 13px",
                  borderRadius: "100px",
                  textTransform: "none",
                  minWidth: "92px",
                  width: 120,
                  borderColor: "transparent !important",
                  "& .Mui-disabled": {
                    borderColor: "transparent !important",
                  },

                  "&:hover": {
                    backgroundColor: "#2993d1",
                  },
                }}
                type="submit"
                disabled={false}
                onClick={handelSelectImage}
              >
                Select
              </Button>
            </Box>
          }
        />
      </Popup>
    </Box>
  );
};

export default UploadImageModal;
