export const featureFlag = {
  enableWeatherApi: false,
  enableCanvasApi: false,
  isLocalStates: false,
  isChanged: true,
  authScreen: true,
  enabledSession: false,
  statusWithChip: true,
  separateStatusLoading: false,
  importExecuteAPI: true,
  isAccountSettingBottom: false,
  isBottomBar: true,
};
