import React, { useState } from "react";
import styles from "./folder.module.scss";
import { Rnd, RndResizeCallback, RndDragCallback } from "react-rnd";
import ControlPanel from "../controlbar";
import { IFolder, ISize, RESIZE_STYLE } from "./folder";
import { getEnableResizing } from "../../utils";
import { Box } from "@mui/material";

const Folder: React.FC<IFolder> = ({
  children,
  folder,
  minimized,
  foldersLength,
  folderCount,
  onClose,
  handleMinimize,
  bringToFront,
  folders,
  isTitleIcon,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  // const wrapperWidth = document.getElementById("folders_wrapper")
  //   ?.offsetWidth as number;
  // const wrapperHeight = document.getElementById("folders_wrapper")
  //   ?.offsetHeight as number;

  const [position, setPosition] = useState<any>(folder.position);
  const [size, setSize] = useState<ISize>({
    width: folder?.width || 850,
    height: folder?.height || 490,
  });
  // console.log("wrapperWidth", wrapperWidth);

  const toggleFullscreen = (_value: string) => {
    if (!isFullscreen) {
      const folderHeight = foldersLength >= 1 ? 120 * folderCount : 0;
      setPosition({ x: 0, y: -folderHeight });
      setSize({ width: window.innerWidth, height: window.innerHeight - 52 });
    } else {
      setSize({ width: folder.width || 1020, height: folder.height || 490 });
      setPosition({ x: 150, y: 205 });
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleDragStop: RndDragCallback = (e, d) => {
    e.stopPropagation();
    e.preventDefault();
    setPosition({ x: d.x, y: d.y });
  };
  const handleResizeStop: RndResizeCallback = (
    _e,
    _direction,
    ref,
    _delta,
    position
  ) => {
    const newWidth = ref.style.width;
    const newHeight = ref.style.height;
    if (newWidth && newHeight) {
      setSize({
        width: newWidth,
        height: newHeight,
      });
    }

    setPosition(position);
  };
  return (
    <>
      <Rnd
        enableResizing={getEnableResizing(!!folder?.isResizeScreen)}
        size={size}
        minHeight={folder?.minHeight}
        minWidth={folder?.minWidth}
        position={position}
        bounds={"#folders_wrapper"}
        className={`  ${styles.folder_rnd} ${
          minimized[folder.id] ? styles.minimized : styles.restored
        }`}
        style={{
          zIndex: folder.isActive ? 999 : 99,
          justifyContent: "center",
          flexDirection: "column",
          border: isTitleIcon ? "solid 1px  #ddd" : "",
          background: "#f3f5f6",
          opacity: isDragging ? 0.6 : "unset",
        }}
        onDrag={handleDragStop}
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragStop={() => {
          setIsDragging(false);
          bringToFront(folder?.id);
        }}
        onResizeStop={handleResizeStop}
        dragHandleClassName="unique_header"
        resizeHandleStyles={RESIZE_STYLE}
      >
        <ControlPanel
          title={folder.folderName || "Page"}
          onMinimize={() => handleMinimize(folder.id)}
          onClose={() => onClose(folder.id)}
          onFullScreen={() => toggleFullscreen(folder.folderName)}
          icon={folder.icon}
          folder={folder}
          openFolderLength={folders.filter((item) => item.isOpen).length}
          isTitleIcon={!!isTitleIcon}
          bringToFront={() => bringToFront(folder.id)}
        />
        {/* showing white screen when user is dragging */}

        {/* {isDragging ? (
          <Box
            sx={{
              background: "#f3f5f6",
              width: "100%",
              height: `calc(100% - 41px)`,
            }}
          />
        ) : (
          children
        )} */}
        {/* {isDragging ? (
          <Box
            sx={{
              background: "#f3f5f6",
              width: "100%",
              height: `calc(100% - 41px)`,

              opacity:
                folders.filter((item) => item.isOpen).length &&
                folders.filter((item) => item.isOpen).length >= 2 &&
                !folder.isActive
                  ? 0.4
                  : "unset",
            }}
          />
        ) : ( */}
        <Box
          sx={{
            background: "#f3f5f6",
            width: "100%",
            height: `calc(100% - 41px)`,

            opacity:
              folders.filter((item) => item.isOpen).length &&
              folders.filter((item) => item.isOpen).length >= 2 &&
              !folder.isActive
                ? 0.4
                : "unset",
          }}
        >
          {children}
        </Box>
        {/* )} */}
      </Rnd>
    </>
  );
};

export default Folder;
