import React from "react"; //  { useEffect }
import { LinearProgress, Typography, Box } from "@mui/material";
import { useMainCtx } from "../../redux/store/useMainCtx";
interface ProgressBarProps {
  initialProgress?: number;
  initialStatus?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = () => {
  const { progress } = useMainCtx((state) => {
    return {
      progress: state.progress,
    };
  });
  // useEffect(() => {
  //   if (progress === 100) {
  //     console.log("1000 progress", [progress, typeof progress]);
  //     return;
  //   }
  //   if (exportValues.status === 1 && progress.toFixed() === "90") {
  //     setProgress(90);
  //     return;
  //   }
  //   if (progress !== 100) {
  //     const intervalId =
  //       setInterval(async () => {
  //         try {
  //           if (exportValues.status === 1) {
  //             console.log("progress inside the Interval", progress);

  //             setProgress(Math.min(progress + 0.2, 100));
  //             // const newProgress = Math.min(progress + 0.2, 100);
  //             // if (newProgress >= 90) {
  //             //   clearInterval(intervalId);
  //             //   setProgress(90);
  //             // } else {

  //             // }
  //           } else {
  //             clearInterval(intervalId);
  //           }
  //         } catch (error) {
  //           console.error("Error fetching status:", error);
  //         }
  //       }, 30000);
  //   }
  //   // return () => clearInterval(intervalId);
  // }, [progress]);

  // useEffect(() => {
  //   if (exportValues.status !== 1) {
  //     setProgress(100);
  //   }
  // }, [exportValues.status]);

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" position={"relative"}>
        <LinearProgress
          sx={{
            height: "22px",
            background: "transparent",
            border: "1px solid #e8edf3",
            borderRadius: "4px",
            "& .MuiLinearProgress-bar": {
              background: "#2993d1",
            },
          }}
          variant="determinate"
          value={progress}
          suppressHydrationWarning
        />
      </Box>
      <Box
        position={"absolute"}
        width={"620px"}
        sx={{
          margin: "0px auto",
          textAlign: "center",
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          fontSize="13px"
          sx={{ color: Math.round(progress) >= 54 ? "white" : "#414B55" }}
        >{`${progress !== 100 ? progress.toFixed(1) : progress}%`}</Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
