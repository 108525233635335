class SessionStorageUtil {
  /**
   * Get an item from sessionStorage with optional JSON parsing.
   * @param {string} key - The key of the item to retrieve.
   * @param {boolean} parse - Whether to parse the item as JSON. Default is false.
   * @param {T} defaultValue - The default value to return if the item does not exist.
   * @returns {T} - The value from sessionStorage, parsed if specified, or the default value.
   */
  static get<T>(
    key: string,
    parse: boolean = false,
    defaultValue: T | null = null
  ): T | null {
    try {
      const item = sessionStorage.getItem(key);
      if (item === null) {
        return defaultValue; // Return default value if item is not found
      }
      return parse ? JSON.parse(item) : item; // Return parsed item or raw string
    } catch (error) {
      console.error(`Error getting item from sessionStorage: ${error}`);
      return defaultValue; // Return default value in case of error
    }
  }

  /**
   * Set an item in sessionStorage, optionally converting it to JSON.
   * @param {string} key - The key of the item to set.
   * @param {T} value - The value to store.
   * @param {boolean} stringify - Whether to stringify the item as JSON. Default is false.
   */
  static set<T>(key: string, value: T, stringify: boolean = false): void {
    try {
      const item = stringify ? JSON.stringify(value) : value;
      sessionStorage.setItem(key, item as string); // Cast to string
    } catch (error) {
      console.error(`Error setting item in sessionStorage: ${error}`);
    }
  }

  /**
   * Remove an item from sessionStorage.
   * @param {string} key - The key of the item to remo  ve.
   */
  static remove(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing item from sessionStorage: ${error}`);
    }
  }

  /**
   * Clear all items from sessionStorage.
   */
  static clear(): void {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error(`Error clearing sessionStorage: ${error}`);
    }
  }
}

export default SessionStorageUtil;

// const myValue = { name: 'Alice', age: 30 };
// SessionStorageUtil.set('myValue', myValue, true); // Store as JSON
// const retrievedValue = SessionStorageUtil.get<{ name: string; age: number }>('myValue', true, null);
// console.log(retrievedValue); // Output: { name: 'Alice', age: 30 }
// SessionStorageUtil.remove('myValue');
// SessionStorageUtil.clear();
