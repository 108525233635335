import { Box, Button, Checkbox } from "@mui/material";
import CustomLabel from "../../../Components/customLabel";
import { Check } from "@material-ui/icons";

const Others = () => {
  return (
    <Box>
      <Box
        pt="16px"
        px={"16px"}
        pb={0}
        sx={{
          height: "calc(100vh - 372px)",
        }}
      >
        <Box display={"flex"} alignItems={"center"} mb={1} pl={1}>
          <Checkbox
            sx={{
              p: 0,
              mr: 1,
              border: "1px solid #d7e0e9",
              borderRadius: "4px",
              height: "20px",
              width: "20px",
              "& .MuiSvgIcon-fontSizeMedium": {
                display: "none",
              },
            }}
            checked={false}
            checkedIcon={<Check sx={{ fontSize: "20px", color: "#2993d1" }} />}
            onChange={(_e) => {}}
          />
          <CustomLabel
            label="Enable instant preview on taskbar"
            height={28}
            display="flex"
            alignItems="center"
          />
        </Box>
        <Box display={"flex"} alignItems={"center"} mb={1} pl={1}>
          <Checkbox
            sx={{
              p: 0,
              mr: 1,
              border: "1px solid #d7e0e9",
              borderRadius: "4px",
              height: "20px",
              width: "20px",
              "& .MuiSvgIcon-fontSizeMedium": {
                // display: "none",
              },
            }}
            checked={true}
            checkedIcon={<Check sx={{ fontSize: "20px", color: "#2993d1" }} />}
            onChange={() => {}}
          />
          <CustomLabel
            label="Enable desktop keyboard shortcuts"
            height={28}
            display="flex"
            alignItems="center"
          />
        </Box>
        <Box display={"flex"} alignItems={"center"} mb={1} pl={1}>
          <Checkbox
            sx={{
              p: 0,
              mr: 1,
              border: "1px solid #d7e0e9",
              borderRadius: "4px",
              height: "20px",
              width: "20px",
              "& .MuiSvgIcon-fontSizeMedium": {
                display: "none",
              },
            }}
            checked={false}
            checkedIcon={<Check sx={{ fontSize: "20px", color: "#2993d1" }} />}
            onChange={() => {}}
          />
          <CustomLabel
            label="Disable accessibility (no auditory cues available)"
            height={28}
            display="flex"
            alignItems="center"
          />
        </Box>
        <Box display={"flex"} alignItems={"center"} mb={1} pl={1}>
          <Checkbox
            sx={{
              p: 0,
              mr: 1,
              border: "1px solid #d7e0e9",
              borderRadius: "4px",
              height: "20px",
              width: "20px",
              "& .MuiSvgIcon-fontSizeMedium": {
                display: "none",
              },
            }}
            checked={false}
            checkedIcon={<Check sx={{ fontSize: "20px", color: "#2993d1" }} />}
            onChange={() => {}}
          />
          <CustomLabel
            label="Enable browser desktop notifications"
            height={28}
            display="flex"
            alignItems="center"
          />
        </Box>
      </Box>
      {/* bottom bar  */}
      <Box
        height={29}
        sx={{
          borderTop: "1px solid #c6d4e080",
          padding: "9px 16px 9px 0",
          boxSizing: "content-box",
          //   mr: "-14px",
        }}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        // mt={"-11px"}
      >
        <Box
          display="flex"
          justifyContent={"end"}
          sx={{
            "& .Mui-disabled": {
              borderColor: "#0000001f !important",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "#c6d4e066",
              borderRadius: "100px",
              border: "1px solid #c6d4e000",
              marginRight: "8px",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              color: "#414b55",
              textTransform: "none",
              width: 85,
              boxShadow:
                "0px 3px 1px -3px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.12)",
              "&:hover": {
                backgroundColor: "rgba(198, 212, 224, 0.4)",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
              },
            }}
            onClick={() => {}}
            disabled={false}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#2993d1",
              border: "1px solid #2993d1",
              color: "#fff",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              borderRadius: "100px",
              textTransform: "none",
              minWidth: "92px",
              marginRight: "8px",
              width: 120,
              "&:hover": {
                backgroundColor: "#2993d1",
              },
            }}
            type="submit"
            disabled={false}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Others;
