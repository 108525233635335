import { useState } from "react";
import { useMainCtx } from "../../../redux/store/useMainCtx";
import { mutate } from "swr";
import { API_URL } from "../../../utils";
import { toast } from "react-toastify";
import SessionStorageUtil from "../../../services/session";
import dayjs from "dayjs";
import http from "../../../services/http";

const useExportPageHook = () => {
  const exportData = SessionStorageUtil.get("exportData", true) as any;
  const { setExportValues, exportValues } = useMainCtx((state) => {
    return {
      setExportValues: state.setExportValues,
      exportValues: state.exportValues,
    };
  });
  const [step, setStep] = useState(exportData?.packageId ? 4 : 1);

  const postExport = (url: string, payload: any) =>
    http.apiRequest(url, "POST", {
      ...payload,
    });

  const handleNext = () => setStep((prev) => prev + 1);
  const handleBack = () => setStep((prev) => prev - 1);

  const [detailsFormData, setDetailsFormData] = useState({
    name: "",
    description: "",
    department: "",
    exportScript: "Select Script",
  });
  const [isLoading, setLoading] = useState(false);

  // const handleNext = () => setStep((prev) => prev + 1);
  // const handleBack = () => setStep((prev) => prev - 1);
  const handleOrgFormSubmit = (value: any) => {
    setExportValues(value);
    setExportValues({ ...exportValues, ...value });
    handleNext();
  };

  const handleDetailsFormSubmit = (value: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDetailsFormData(value);
      setExportValues({ ...exportValues, ...value });
      handleNext();
    }, 500);
  };

  const handleExecute = async () => {
    setLoading(true);
    const payload = {
      name: exportValues.name,
      description: exportValues.description,
      connection: {
        client: exportValues.clientId,
        secret: exportValues.secret,
        region: exportValues.region,
      },
      params: {
        "parallel-executions": "25",
        "build-display-name": "",
        "job-name": "",
        lob: exportValues.department,
        export: {
          "business-unit": "",
          "genesys_cloud.location": exportValues.location,
        },
      },
      payload: {
        "export-script-path": exportValues.exportScript,
      },
      "callback-url": "",
    };

    try {
      const response = await mutate(
        `${API_URL.POST_EXPORT_EXECUTE}`,
        postExport(`${API_URL.POST_EXPORT_EXECUTE}`, payload),
        {
          optimisticData: payload,
          rollbackOnError: true,
        }
      );
      setLoading(false);
      SessionStorageUtil.set(
        "exportData",
        {
          packageId: response?.id,
          name: exportValues?.name,
          description: exportValues?.description,
          department: exportValues?.department,
          location: exportValues?.location,
          startTime: dayjs(response["created-time"]).format(
            "ddd, DD MMM YYYY HH:mm:ss"
          ),
          status: response?.output.status,
          message: response?.output?.message,
          output: response?.output,
        },
        true
      );

      setExportValues({
        ...exportValues,
        status: response?.output.status,
        message: response?.output?.message,
        packageId: response?.id,
        startTime: dayjs(response["created-time"]).format(
          "ddd, DD MMM YYYY HH:mm:ss"
        ),
      });
      toast.success("Successfully start execution", { autoClose: 2000 });
      handleNext();
    } catch (error) {
      console.log("error from execute post api", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    step,
    isLoading: isLoading,
    handleBack,
    handleNext,
    handleOrgFormSubmit,
    // details form
    detailsFormData,
    handleDetailsFormSubmit,
    handleExecute,
  };
};

export default useExportPageHook;
