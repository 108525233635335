import { Box, Button, Typography } from "@mui/material";
import CustomLabel from "../../../../../Components/customLabel";
import CustomPopover from "../../../../../../Components/CustomPopover/CustomPopover";
import { useEffect, useState } from "react";
import ConfirmPopup from "../../../../../../Components/ConfiromPopup/ConfirmPopup";
import SessionStorageUtil from "../../../../../../services/session";
interface MyImagesProps {
  setSelectedImage: any;
  selectedImage: {
    title: string;
    url: string;
  } | null;
}
const MyImages: React.FC<MyImagesProps> = ({
  selectedImage,
  setSelectedImage,
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const [images, setImages] = useState<any>([]);
  const storedImages = SessionStorageUtil.get("imageList") as string;
  // Load images from session storage on initial render
  useEffect(() => {
    if (storedImages) {
      setImages(JSON.parse(storedImages));
    }
  }, [storedImages]);

  // Function to handle image upload
  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newImage = {
          name: file.name,
          src: reader.result,
        };
        const updatedImages = [...images, newImage];
        setImages(updatedImages);
        // Save to session storage
        SessionStorageUtil.set("imageList", JSON.stringify(updatedImages));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelect = (item: any) => {
    setSelectedImage(item);
  };

  const handleOk = () => {
    SessionStorageUtil.remove("imageList");
    setImages([]);
    setSelectedImage(null);
    setShowWarning(false);
  };
  return (
    <Box p={"12px"}>
      <CustomLabel
        label="Use My Image"
        mb="8px"
        height={22}
        display="flex"
        alignItems="center"
        color="#2993d1"
        fontSize={"16px"}
        paddingBottom={"6px"}
        paddingLeft={"8px"}
        borderBottom={"1px dashed #d2dce5"}
      />
      <Box display={"flex"} alignItems={"center"} mb={3}>
        <Button
          disabled={false}
          component="label"
          sx={{
            textTransform: "none",
            background: "#e6eff2",
            color: "#414B55",
            padding: "3px 16px",
            fontSize: "13px",
            fontWeight: 400,
            "&:hover": {
              background: "#e6eff2",
            },
          }}
        >
          Upload
          <input
            accept="image/*"
            hidden
            type="file"
            onChange={handleImageUpload}
          />
        </Button>
        <Box
          id="upload_img_info"
          sx={{
            width: 24,
            height: 24,
            backgroundImage: "url(/assets/icons/info_icon.png)",
            backgroundSize: "24px 24px",
            ml: 1,
          }}
        />
      </Box>

      <CustomLabel
        label="My Previous Images"
        mb="8px"
        height={22}
        display="flex"
        alignItems="center"
        color="#2993d1"
        fontSize={"16px"}
        paddingBottom={"6px"}
        paddingLeft={"8px"}
        borderBottom={"1px dashed #d2dce5"}
      />
      <Box>
        <Button
          disabled={images.length === 0 && selectedImage === null}
          sx={{
            textTransform: "none",
            background: "#e6eff2",
            color: "#414B55",
            padding: "3px 16px",
            fontSize: "13px",
            fontWeight: 400,
            mb: images.length === 0 ? 1 : 2,
            "&:hover": {
              background: "#e6eff2",
            },
          }}
          onClick={() => {
            setShowWarning(true);
          }}
        >
          Clear All
        </Button>

        {images.length === 0 && selectedImage === null && (
          <Typography sx={{ fontSize: "13px", color: "#404b54" }}>
            There are no images in this folder.
          </Typography>
        )}
        {images.length ? (
          <Box display={"flex"} flexWrap={"wrap"} gap={"10px"}>
            {images.map((item: any) => (
              <Box
                p="6px"
                textAlign={"center"}
                borderRadius={"3px"}
                pt={"48px"}
                bgcolor={item.name === selectedImage?.title ? "#3c77ff1a" : ""}
                border={`1px solid  ${
                  item.name === selectedImage?.title
                    ? "#3c77ff4d"
                    : "transparent"
                }`}
                sx={{
                  "&:hover": {
                    bgcolor: "#3c77ff1a",
                  },
                }}
                onClick={() => {
                  handleSelect({ url: item.src, title: item.name });
                }}
              >
                <img
                  src={item.src}
                  alt=""
                  width={140}
                  height={80}
                  style={{
                    boxShadow: "0 1px 3px rgba(0,0,0,0.5)",
                  }}
                />
                <Typography
                  sx={{ fontSize: "13px", mt: 0.6, color: "#404b54" }}
                >
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          ""
        )}
      </Box>
      <ConfirmPopup
        popupVisible={showWarning}
        id="clear_image_warning"
        handleCancel={() => {
          setShowWarning(false);
        }}
        handleOk={handleOk}
        message={"Are you sure you want to clear all previous images?"}
      />
      <CustomPopover
        id="upload_img_info"
        contnent={"The file size cannot exceed 8 MB."}
        width={250}
      />
    </Box>
  );
};

export default MyImages;
