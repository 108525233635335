import { Popover as MuiPopover, Typography } from "@mui/material";

const Popover = ({ handleClose, anchorEl, popContent }: any) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <MuiPopover
      id={id}
      open={open}
      anchorEl={anchorEl || true}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        pointerEvents: "none",
        // marginTop: "10px",
        "& .MuiPaper-root": {
          width: 330,
          border: "1px solid red",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: 13,
          padding: "10px !important",
          fontWeight: 500,
          color: "#414b55",
          // color: "#fff",

          // background: "#2993d1",
        }}
      >
        {popContent}
      </Typography>
    </MuiPopover>
  );
};

export default Popover;
