import { Popover } from "devextreme-react";
import styles from "./styles.module.scss";
import { Typography } from "@mui/material";

interface CustomPopoverProps {
  id: string;
  contnent: string | any;
  width?: number;
}
const CustomPopover: React.FC<CustomPopoverProps> = ({
  id,
  contnent,
  width = 500,
}) => {
  return (
    <Popover
      target={`#${id}`}
      showEvent="mouseenter"
      hideEvent="mouseleave"
      wrapperAttr={{
        id: id,
        class: styles.custom_popover,
      }}
      position={"bottom"}
      width={width}
      showTitle={false}
      title={"title"}
      // animation={undefined}
    >
      <Typography sx={{ fontSize: "13px", color: "#404b54" }}>
        {contnent}
      </Typography>
    </Popover>
  );
};

export default CustomPopover;
