import { Box, Typography } from "@mui/material";

const DataNotFound = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999,
      }}
    >
      <Typography variant="h4" color="white">
        Data not found!
      </Typography>
    </Box>
  );
};

export default DataNotFound;
