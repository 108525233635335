import { Box, Button } from "@mui/material";
import CustomLabel from "../Components/customLabel";
import Loading from "../../Components/Loading/Loading";
import React from "react";
import Popover from "../../Components/popover/popover";
import TopHeading from "../Components/TopHeading";

const JobDetails = ({
  isChanged,
  handleBack,
  handleExecute,
  detailsFormData,
  isLoading,
  exportFromLabel,
}: any) => {
  const maxDesLength = detailsFormData?.description?.length > 53;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <TopHeading title="Summary" />
      <Box sx={{ padding: "24px 40px" }} height={isChanged ? "440px" : "454px"}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Box width={"100%"} display={"flex"}>
              <Box width={"25%"}>
                <CustomLabel
                  label="Exporting From:"
                  mb="8px"
                  height={22}
                  display="flex"
                  alignItems="center"
                />
                <CustomLabel
                  label="Name:"
                  mb="8px"
                  height={22}
                  display="flex"
                  alignItems="center"
                />
                <CustomLabel
                  label="Description:"
                  mb="8px"
                  height={22}
                  display="none"
                  alignItems="center"
                />
                <CustomLabel
                  label="Export Script:"
                  mb="8px"
                  height={22}
                  display="none"
                  alignItems="center"
                />
              </Box>
              <Box width={"75%"}>
                <CustomLabel
                  mb="8px"
                  height={22}
                  display="flex"
                  alignItems="center"
                  className="ellipsis-text"
                  label={exportFromLabel || "-"}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  textWrap="nowrap"
                />
                <CustomLabel
                  mb="8px"
                  height={22}
                  display="flex"
                  alignItems="center"
                  label={detailsFormData?.name || "-"}
                />
                <Box
                  display={"none"}
                  alignItems={"center"}
                  height={22}
                  mb="8px"

                  // pr={"22px"}
                >
                  <CustomLabel
                    width={450}
                    display="block"
                    label={detailsFormData?.description || "-"}
                    className="ellipsis-text"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    textWrap="nowrap"
                  />
                  {maxDesLength && (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        backgroundImage: "url(/assets/icons/info_icon.png)",
                        backgroundSize: "24px 24px",
                      }}
                      onMouseEnter={handleClick as any}
                      onMouseLeave={handleClose as any}
                    />
                  )}
                </Box>
                <CustomLabel
                  mb="8px"
                  height={22}
                  display="none"
                  alignItems="center"
                  label={detailsFormData.exportScript || "Pipline_22"}
                />
              </Box>
              <Popover
                handleClose={handleClose}
                anchorEl={anchorEl}
                popContent={detailsFormData?.description}
              />
            </Box>
            <Box width={"100%"} display={"flex"} mb="8px">
              <CustomLabel
                label="Description:"
                // mb="8px"
                height={22}
                display="flex"
                alignItems="center"
                width={"25%"}
              />
              <CustomLabel
                width={"75%"}
                display="block"
                label={detailsFormData?.description || "-"}
              />
            </Box>
            <Box width={"100%"} display={"flex"} mb={"8px"}>
              <CustomLabel
                label="Export Script:"
                height={22}
                display="flex"
                alignItems="center"
                width={"25%"}
              />
              <CustomLabel
                width={"75%"}
                display="block"
                label={detailsFormData.exportScript || "-"}
              />
            </Box>
          </>
        )}
      </Box>

      {/* Bottom buttons */}
      <Box
        height={29}
        sx={{
          borderTop: "1px solid #c6d4e080",
          padding: "9px 16px 9px 0",
          boxSizing: "content-box",
        }}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Box
          display="flex"
          justifyContent={"end"}
          sx={{
            "& .Mui-disabled": {
              borderColor: "#0000001f !important",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "#c6d4e066",
              borderRadius: "100px",
              border: "1px solid #c6d4e000",
              marginRight: "8px",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              color: "#414b55",
              textTransform: "none",
              width: 85,
              boxShadow:
                "0px 3px 1px -3px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.12)",
              "&:hover": {
                backgroundColor: "rgba(198, 212, 224, 0.4)",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
              },
            }}
            onClick={handleBack}
            disabled={isLoading}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#2993d1",
              border: "1px solid #2993d1",
              color: "#fff",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              borderRadius: "100px",
              textTransform: "none",
              minWidth: "92px",
              marginRight: "8px",
              width: 120,
              "&:hover": {
                borderColor: "#2993d1",
                backgroundColor: "#2993d1",
              },
            }}
            onClick={handleExecute}
            disabled={isLoading}
          >
            Execute
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default JobDetails;
