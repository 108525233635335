import * as React from "react";
import Box from "@mui/material/Box";
// import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { signOut as authSignOut } from "@aws-amplify/auth";
import { Typography } from "@mui/material";
import { InfoOutlined } from "@material-ui/icons";
import { useMainCtx } from "../../redux/store/useMainCtx";
// import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function AccountMenu({ handleOpenModal }: any) {
  const { userDetails } = useMainCtx((state) => {
    return {
      userDetails: state.userDetails,
    };
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await authSignOut();
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };
  // const element = getComputedStyle(
  //   document.querySelector(".MuiPaper-root") as any
  // );
  // console.log("element", subtractPixels(element.getPropertyValue("top"), 22));

  // const dynamicTopValue = element.getPropertyValue("top") ? subtractPixels(element.getPropertyValue("top"), 22)

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            width: 24,
            height: 24,
            backgroundImage: "url(/assets/icons/profile_ico.png)",
            backgroundSize: "24px 72px",
            backgroundPosition: open ? "0 -48px" : "0 0",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
            "&:hover": {
              backgroundPosition: open ? "0 -48px" : "0 -24px",
            },
          }}
          onClick={handleClick}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // transformOrigin={{
        //   vertical: 20,
        //   horizontal: 0,
        // }}
        sx={{
          "& .MuiPaper-root": {
            top: "38px !important",
            // left: "628px !important",
            borderRadius: "3px",
            boxShadow: "0 2px 6px rgba(0,0,0,.5)",
            width: 200,
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 8,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={1}
          mb={"8px"}
          justifyContent={"center"}
        >
          <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
            {" "}
            {userDetails.username}
          </Typography>
        </Box>
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem> */}
        <Divider sx={{ mb: "8px", borderColor: "#e8eef2" }} />

        {/* <MenuItem
          onClick={handleOpenPersonalFolder}
          sx={{
            fontSize: "13px",
            mx: 1,
            p: 0,
            height: 32,
            mb: "8px",
            color: "#414b55",
            "&:hover": {
              background: "rgba(198,212,224,.4)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "10px !important",
              mx: "6px !important",
              mt: "2px",
            }}
          >
            <Settings fontSize="small" sx={{ fontSize: "16px" }} />
          </ListItemIcon>
          Personal
        </MenuItem> */}

        <MenuItem
          onClick={handleOpenModal}
          sx={{
            fontSize: "13px",
            mx: 1,
            p: 0,
            height: 32,
            mb: "8px",
            color: "#414b55",
            "&:hover": {
              background: "rgba(198,212,224,.4)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "10px !important",
              mx: "6px !important",
              mt: "2px",
            }}
          >
            <InfoOutlined fontSize="small" sx={{ fontSize: "18px" }} />
          </ListItemIcon>
          About
        </MenuItem>
        <Divider sx={{ mb: "8px", borderColor: "#e8eef2" }} />
        <MenuItem
          onClick={handleLogout}
          sx={{
            fontSize: "13px",
            mx: 1,
            p: 0,
            height: 32,
            color: "#414b55",
            // mb: "8px",
            "&:hover": {
              background: "rgba(198,212,224,.4)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "10px !important",
              mx: "6px !important",
              mt: "2px",
            }}
          >
            <Logout sx={{ fontSize: "18px" }} />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
