export interface IFolder {
  onClose: (id: string) => void;
  onClick?: (id: string) => void;
  children: React.ReactNode;
  folder: {
    id: string;
    isOpen: boolean;
    folderName: string;
    isActive?: boolean;
    width?: number;
    height?: number;
    bgColor?: string;
    icon?: string;
    bgImage?: string;
    minHeight?: number;
    minWidth?: number;
    isResizeScreen?: boolean;
    position?: {
      x: number;
      y: number;
    };
  };
  folders: any[];
  minimized: { [key: string]: boolean };
  handleMinimize: (component: string) => void;
  foldersLength: number;
  folderCount: number;
  bringToFront: (id: string) => void;
  isTitleIcon?: boolean;
}

export interface IPosition {
  x: number;
  y: number;
}

export interface ISize {
  width: number | string;
  height: number | string;
}

export const RESIZE_STYLE = {
  top: { cursor: "ns-resize" },
  bottom: { cursor: "ns-resize" },
  left: { cursor: "ew-resize" },
  right: { cursor: "ew-resize" },
  topRight: { cursor: "nesw-resize" },
  topLeft: { cursor: "nwse-resize" },
  bottomRight: { cursor: "nwse-resize" },
  bottomLeft: { cursor: "nesw-resize" },
};
