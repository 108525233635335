import { Box, Tabs, Tab, IconButton, Typography } from "@mui/material";
import "devextreme/data/odata/store";
import { useState } from "react";
import styles from "./styles.module.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Overview from "./Tabs/Overview/Overview";

interface GridPageProps {
  handleDone: () => void;
}
const GridPage: React.FC<GridPageProps> = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleTabChange = (_event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      height={"100%"}
      className={styles.grid_wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      <Box display="flex" height={"100%"}>
        {/* Side Panel */}
        <Box
          sx={{
            background: "white !important",
            transition: "width 0.3s !important",
            width: collapsed ? "60px" : "180px",
            overflow: "hidden",
            backgroundColor: "#f5f5f5",
            borderRight: "1px solid #e3e9f1",
            minWidth: 20,
            whiteSpace: "nowrap",
          }}
          className={styles.left_side}
        >
          <IconButton
            onClick={handleCollapse}
            className={styles.collapse_btn}
            sx={{
              p: 0,
              position: "absolute",
              left: !collapsed ? "170px" : "50px",
              top: "20px",
            }}
          >
            {collapsed ? (
              <ChevronRightIcon sx={{ fontSize: "18px" }} />
            ) : (
              <ChevronLeftIcon sx={{ fontSize: "18px" }} />
            )}
          </IconButton>
          <Tabs
            orientation="vertical"
            value={selectedTab}
            onChange={handleTabChange}
            sx={{ mt: 2, px: 1.6 }}
          >
            <Tab
              iconPosition="start"
              icon={
                <img
                  src="/assets/icons/overview_icon.png"
                  height={"16px"}
                  alt=""
                />
              }
              label={!collapsed ? "Overview" : ""}
              sx={{
                justifyContent: !collapsed ? "flex-start" : "",
                minHeight: "28px",
                fontSize: "13px",
                textTransform: "none",
                padding: "3px 11px",
                mb: 0.4,
                width: collapsed ? 32 : "auto",
                minWidth: 20,
              }}
            />
            <Tab
              iconPosition="start"
              icon={
                <img
                  src="/assets/icons/drop_logs_icon.png"
                  height={"16px"}
                  alt=""
                />
              }
              label={!collapsed ? "Logs" : ""}
              sx={{
                justifyContent: !collapsed ? "flex-start" : "",
                minHeight: "28px",
                fontSize: "13px",
                textTransform: "none",
                padding: "3px 11px",
                mb: 0.4,
                width: collapsed ? 32 : "auto",
                minWidth: 20,
              }}
            />
          </Tabs>
        </Box>

        <Box
          flex={1}
          p={1.6}
          pb={0}
          bgcolor={"white"}
          height={"calc(100% - 76px)"}
        >
          {selectedTab === 0 && <Overview />}
          {selectedTab === 1 && (
            <Typography variant="h4">Logs Content</Typography>
          )}
          {selectedTab === 2 && (
            <Typography variant="h4">About Content</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GridPage;
