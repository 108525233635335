import { Typography, TypographyStyle } from "@mui/material";
import { ReactElement } from "react";

interface CustomLabelProps extends TypographyStyle {
  label: string | ReactElement;
  className?: string | any;
}

const CustomLabel: React.FC<CustomLabelProps> = ({
  label,
  className,
  ...props
}) => {
  return (
    <Typography
      className={className}
      sx={{
        ...props,
        fontSize: props.fontSize ? props.fontSize : 13,
        fontFamily: "Verdana,Arial,sans-serif",
        color: props.color ? props.color : "#414B55",
      }}
    >
      {label}
    </Typography>
  );
};

export default CustomLabel;
