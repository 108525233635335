import { Box } from "@mui/material";
import { TabPanel } from "devextreme-react";
import styles from "./styles.module.scss";
import { useState } from "react";
import Account from "./Tabs/Account/Account";
import DisplayPreferences from "./Tabs/DisplayPreferences/DisplayPreferences";
import Others from "./Tabs/Others/Others";

const PersonalPage = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabPanelDataSource = [
    {
      title: "Account",
      tabId: "tab1",
    },
    {
      title: "Display Preferences",
      tabId: "tab2",
    },
    {
      title: "Others",
      tabId: "tab3",
    },
  ];
  return (
    <>
      <Box className={styles.tabpanel_vertical_wrapper}>
        <TabPanel
          tabsPosition={"top"}
          stylingMode={"secondary"}
          //   iconPosition={
          //      "start"
          //   }
          width="100%"
          height={"100%"}
          elementAttr={{
            style: "height:100%;",
          }}
          dataSource={tabPanelDataSource}
          // onItemContextMenu={(el) => {
          //     setContextId(element.id);

          //     console.log("el", el);
          // }}
          onSelectedIndexChange={(value) => setSelectedIndex(value)}
          itemComponent={() => {
            if (selectedIndex === 0) {
              return <Account />;
            }
            if (selectedIndex === 1) {
              return <DisplayPreferences />;
            }
            if (selectedIndex === 2) {
              return <Others />;
            }
          }}
        />
      </Box>
    </>
  );
};

export default PersonalPage;
