import { Box, Button, Chip } from "@mui/material";
import CustomLabel from "../Components/customLabel";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import TopHeading from "../Components/TopHeading";
import useExecutionHook from "./hooks/useExecutuonHook";
import { featureFlag } from "../../contants/feauture";
import { ViewLogsPopup } from "../Components/ViewLogsPopup";

const Execute = ({
  isChanged,
  statusMessage,
  status,
  packageId,
  exportValues,
  handleDone,
}: any) => {
  const { isLoading, popupVisible, hide, showPopup } = useExecutionHook({
    exportValues,
  });

  return (
    <Box>
      <TopHeading title="Export Progress" />
      <Box
        sx={{
          padding: "24px 40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        height={isChanged ? "440px" : "454px"}
        id="execute_container"
      >
        <>
          <Box>
            <Box width={"100%"} display={"flex"}>
              <Box width={"25%"}>
                <CustomLabel
                  label="Exporting From:"
                  mb="8px"
                  height={"22px !important"}
                  display="flex"
                  alignItems="center"
                />

                <CustomLabel
                  label="Name:"
                  mb="8px"
                  height={"22px !important"}
                  display="flex"
                  alignItems="center"
                />
              </Box>

              <Box width={"75%"}>
                <CustomLabel
                  label={
                    exportValues.department !== undefined &&
                    exportValues.location !== undefined
                      ? `${exportValues.department} @ ${exportValues.location}`
                      : "-"
                  }
                  mb="8px"
                  height={22}
                  display="flex"
                  alignItems="center"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  textWrap="nowrap"
                />
                <CustomLabel
                  label={exportValues?.name || "-"}
                  mb="8px"
                  height={22}
                  display="flex"
                  alignItems="center"
                />
              </Box>
            </Box>
            <Box width={"100%"} display={"flex"} mb="8px">
              <CustomLabel
                label="Description:"
                // mb="8px"
                height={22}
                display="flex"
                alignItems="center"
                width={"25%"}
              />
              <CustomLabel
                width={"75%"}
                display="block"
                label={exportValues?.description || "-"}
              />
            </Box>
            {/* <Box width={"100%"} display={"flex"}>
            <CustomLabel
              label="Export Script:"
              mb="8px"
              height={22}
              display="flex"
              alignItems="center"
              width={"25%"}
            />
            <CustomLabel
              width={"75%"}
              height={22}
              mb="8px"
              alignItems="center"
              display="flex"
              label={detailsFormData.exportScript || "Pipline_22"}
            />
          </Box> */}
            {/* start time added  */}
            <Box width={"100%"} display={"flex"}>
              <CustomLabel
                label="Start Time:"
                mb="8px"
                height={22}
                display="flex"
                alignItems="center"
                width={"25%"}
              />
              <CustomLabel
                width={"75%"}
                height={22}
                mb="8px"
                alignItems="center"
                display="flex"
                label={exportValues.startTime || "-"}
              />
            </Box>
            {/* end time added */}
            <Box width={"100%"} display={"none"}>
              <CustomLabel
                label="End Time:"
                mb="8px"
                height={22}
                display="flex"
                alignItems="center"
                width={"25%"}
              />
              <CustomLabel
                width={"75%"}
                height={22}
                mb="8px"
                alignItems="center"
                display="flex"
                label={exportValues.endTime || "-"}
              />
            </Box>

            <CustomLabel
              label="Status"
              mb="8px"
              mt={"24px"}
              fontWeight={600}
              height={22}
            />

            <Box
              mb="8px"
              display={"flex"}
              width={"100%"}
              alignItems={"self-start"}
              justifyContent={"space-between"}
            >
              <CustomLabel
                label="Package ID:"
                width={"20%"}
                pl={"20px"}
                // mb="8px"
                display="flex"
                alignItems="center"
                height={22}
              />
              <CustomLabel
                label={packageId}
                // mb="8px"
                display="flex"
                alignItems="center"
                height={22}
                width={"75%"}
              />
              {/* {isLoading ? (
                <Box width={"75%"}>
                  <Skeleton
                    animation="wave"
                    width={260}
                    height={32}
                    sx={{
                      mb: "-5px",
                      mt: "-5px",
                    }}
                  />
                </Box>
              ) : (
                <CustomLabel
                  label={packageId}
                  // mb="8px"
                  display="flex"
                  alignItems="center"
                  height={22}
                  width={"75%"}
                />
              )} */}
            </Box>
            {exportValues.duration && (
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                mb="8px"
              >
                <CustomLabel
                  label="Duration:"
                  pl={"20px"}
                  height={22}
                  display="flex"
                  alignItems="center"
                  width={"20%"}
                />
                <CustomLabel
                  width={"75%"}
                  height={22}
                  alignItems="center"
                  display="flex"
                  label={exportValues.duration}
                />
              </Box>
            )}
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              mb="8px"
            >
              <Box width={"20%"} />
              <Box width={"75%"} display={"flex"} flexDirection={"column"}>
                {featureFlag.separateStatusLoading && isLoading && (
                  <Box
                    height={20}
                    mb={"8px"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    {/* <Skeleton
                      animation="wave"
                      width={160}
                      height={33}
                      sx={{
                        mb: "-2px",
                        mt: "-7px",
                      }}
                    /> */}
                    <img
                      src="/assets/icons/dots_icon.svg"
                      alt="running_icon"
                      width={70}
                      style={{ marginLeft: "-8px" }}
                    />
                  </Box>
                )}

                <>
                  <Box display={"flex"} alignItems={"center"} gap={1} mb="8px">
                    {statusMessage !== " " ? (
                      <>
                        <Chip
                          variant="outlined"
                          color={status === 5 ? "error" : "success"}
                          size="small"
                          label={statusMessage}
                          sx={{
                            height: 20,
                            borderRadius: "4px",
                            "& .MuiChip-deleteIconSmall": {
                              marginRight: "5px",
                            },
                          }}
                        />
                        {status === 1 && (
                          <img
                            src="/assets/icons/running_icon.svg"
                            alt="running_icon"
                            width={14}
                          />
                        )}
                        {status === 5 && (
                          <img
                            src="/assets/icons/complete_error_icon.svg"
                            alt="running_icon"
                            width={14}
                          />
                        )}
                        {status === 4 && (
                          <img
                            src="/assets/icons/stop_running_icon.svg"
                            alt="running_icon"
                            width={14}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <CustomLabel label={statusMessage} />
                        {status === 5 && (
                          <img
                            src="/assets/icons/complete_error_icon.svg"
                            alt="running_icon"
                            style={{ marginTop: "2px" }}
                          />
                        )}
                        {status === 4 && (
                          <img
                            src="/assets/icons/stop_running_icon.svg"
                            alt="running_icon"
                            style={{ marginTop: "2px" }}
                          />
                        )}
                        {status === 1 && (
                          <img
                            src="/assets/icons/running_icon.svg"
                            alt="running_icon"
                            style={{ marginTop: "2px" }}
                          />
                        )}
                      </>
                    )}
                  </Box>
                </>
                <CustomLabel
                  width={400}
                  label={
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                      sx={{
                        fontSize: 13,
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={showPopup}
                        sx={{
                          // color: "#057FEB",
                          color: "#2993D1",
                          fontSize: "13px",
                          textDecoration: "underline",
                          textTransform: "none",
                          p: 0,
                          "&:hover": {
                            background: "none",
                            textDecoration: "underline",
                          },
                        }}
                      >
                        View Logs
                      </Button>
                      {/* <a
                        href=""
                       
                        target="_blank"
                        style={{ color: "#057FEB" }}
                      >
                        View Logs
                      </a> */}
                    </Box>
                  }
                />
              </Box>
            </Box>
          </Box>

          <ProgressBar initialStatus={exportValues.status} />
        </>
      </Box>

      <Box
        height={29}
        sx={{
          borderTop: "1px solid #c6d4e080",
          padding: "9px 16px 9px 0",
          boxSizing: "content-box",
        }}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Box
          display="flex"
          justifyContent={"end"}
          sx={{
            "& .Mui-disabled": {
              borderColor: "#0000001f !important",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "#2993d1",
              border: "1px solid #2993d1",
              color: "#fff",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              borderRadius: "100px",
              textTransform: "none",
              minWidth: "92px",
              marginRight: "8px",
              width: 120,
              "&:hover": {
                borderColor: "#2993d1",
                backgroundColor: "#2993d1",
              },
            }}
            onClick={handleDone}
          >
            Done
          </Button>
        </Box>
      </Box>

      <ViewLogsPopup popupVisible={popupVisible} hide={hide} isImport={false} />
    </Box>
  );
};

export default Execute;
