import React from "react";
import styles from "./styles.module.scss";
import { IControlPanel } from "./controlber";

const ControlPanel: React.FC<IControlPanel> = ({
  title,
  onMinimize,
  onClose,
  onFullScreen,
  // icon,
  folder,
  openFolderLength,
  isTitleIcon,
  bringToFront,
}) => {
  return (
    <div
      className={styles.control_panel}
      style={{
        borderBottom: isTitleIcon ? "1px solid #c6d4e080" : "",
        background: isTitleIcon ? "" : "#2993D1",
        paddingLeft: isTitleIcon ? 50 : 16,
        opacity:
          openFolderLength && openFolderLength >= 2 && !folder.isActive
            ? 0.4
            : "unset",
        // backgroundImage: isTitleIcon
        //   ? `url(${
        //       icon?.includes("/assets/")
        //         ? "/assets/icons/small_folder.png"
        //         : icon
        //     })`
        //   : "",
      }}
      onClick={(e) => {
        e.stopPropagation();
        bringToFront();
      }}
    >
      <div className={styles.title_wrap}>
        <div
          className={`${styles.control_panel_title} unique_header`}
          style={{
            color: isTitleIcon ? "414b55d9" : "#fff",
            opacity: isTitleIcon ? "unset" : 0.7,
          }}
        >
          {title}
        </div>
        <div className={`${styles.element_header} unique_header`} />
      </div>
      <div
        className={styles.control_panel_buttons}
        style={{
          padding: isTitleIcon ? "8px 14px 8px 0px" : "5px 16px 5px 0px",
        }}
      >
        {folder.isMinimize && isTitleIcon && (
          <div className={styles.control_panel_minimize} onClick={onMinimize} />
        )}
        {folder.isFullScreen && (
          <div
            className={styles.control_panel_full_screen}
            onClick={onFullScreen}
          />
        )}
        <div
          className={`${styles.control_panel_close} ${
            !isTitleIcon && styles.close_icon_isChanged
          }`}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default ControlPanel;
