import { Box, Button } from "@mui/material";
import CustomLabel from "../../../Components/customLabel";
import CustomTextField from "../../../Components/CustomTextField";
import CustomSelect from "../../../Components/CustomSelect";
import * as yup from "yup";
import { useFormik } from "formik";
import { Warning } from "@material-ui/icons";
import { useMainCtx } from "../../../../redux/store/useMainCtx";
import ChangePasswordModal from "./ChangePasswordModal";
import useAccount from "./useAccount";
import CustomPopover from "../../../../Components/CustomPopover/CustomPopover";

const Account = () => {
  const { userDetails } = useMainCtx((state) => {
    return {
      userDetails: state.userDetails,
    };
  });

  const {
    showChangePassodal,
    hideChangePassModal,
    changePassModalVisible,
    handleUpdatePassword,
  } = useAccount();

  const validationSchema = yup.object({
    name: yup.string().required("required"),
    description: yup.string().required("required"),
    email: yup.string().required("required"),
    language: yup
      .string()
      .test("region", "required", (value) => value !== "Select Language")
      .required("required"),
  });

  const formik = useFormik({
    initialValues: {
      name: userDetails.username || "demo@eemaan.com",
      description: "",
      email: "",
      language: "English",
      password: "******************",
    },
    validationSchema: validationSchema,
    onSubmit: (_values) => {},
  });

  return (
    <Box
      component="form"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onSubmit={(e) => {
        formik.handleSubmit(e);
      }}
    >
      <Box
        width={"100%"}
        display={"flex"}
        pt="16px"
        pl="24px"
        sx={{
          height: "calc(100vh - 372px)",
        }}
      >
        <Box width={"30%"}>
          <CustomLabel
            label="Name:"
            mb="8px"
            height={28}
            display="flex"
            alignItems="center"
          />
          <CustomLabel
            label="Description:"
            mb="8px"
            height={28}
            display="flex"
            alignItems="center"
          />
          <CustomLabel
            label="Email:"
            mb="8px"
            height={28}
            display="flex"
            alignItems="center"
          />
          <CustomLabel
            label="Display Language:"
            mb="8px"
            height={28}
            display="flex"
            alignItems="center"
          />
          <CustomLabel
            label="Password:"
            mb="8px"
            height={28}
            display="flex"
            alignItems="center"
          />
        </Box>
        {/* //left  */}
        <Box width={"70%"}>
          <Box mb={"8px"} width={270}>
            <CustomLabel
              label={formik.values.name || "demo@eemaan.com"}
              mb="8px"
              height={28}
              display="flex"
              alignItems="center"
            />
          </Box>
          <Box
            mb={"8px"}
            width={270}
            sx={{
              "& .MuiInputBase-root": {
                //   padding: "3px 11px",
                //   width: "270px",
              },
            }}
          >
            <CustomTextField
              props={{
                fullWidth: true,
                inputProps: { maxLength: 128 },
                name: "description",

                value: formik.values.description,
                onChange: formik.handleChange,
                error:
                  formik.touched.description &&
                  Boolean(formik.errors.description),
                helperText: formik.touched.description && (
                  <Warning
                    sx={{ fontSize: 20, mt: "4px" }}
                    className="error_icon"
                  />
                ),
              }}
            />
          </Box>
          <Box
            mb={"8px"}
            width={297}
            display={"flex"}
            alignItems={"center"}
            gap={0.7}
          >
            <CustomTextField
              props={{
                name: "email",
                fullWidth: true,
                // placeholder: "john.doe@gmail.com",
                //   inputProps: { maxLength: 16 },
                value: formik.values.email.replace(" ", "-"),
                onChange: formik.handleChange,
                error: formik.touched.email && Boolean(formik.errors.email),
                helperText: formik.touched.email && (
                  <Warning
                    sx={{ fontSize: 20, mt: "4px" }}
                    className="error_icon"
                  />
                ),
              }}
            />
            <Box
              id="email_info"
              sx={{
                width: 24,
                height: 24,
                backgroundImage: "url(/assets/icons/info_icon.png)",
                backgroundSize: "24px 24px",
              }}
            />
          </Box>

          <Box
            mb={"8px"}
            width={270}
            sx={{
              "& .MuiSelect-select": {
                height: "22px !important",
              },
            }}
          >
            <CustomSelect
              props={{
                fullWidth: true,
                name: "language",
                value: formik.values.language,
                onChange: formik.handleChange,
                error:
                  formik.touched.language && Boolean(formik.errors.language),
                helperText: formik.touched.language && (
                  <Warning
                    sx={{ fontSize: 20, mt: "4px" }}
                    className="error_icon"
                  />
                ),
              }}
              isStringArray
              options={["Select Language", "English"]}
            />
          </Box>
          <Box mb={"8px"} width={270}>
            <CustomLabel
              label="*****************"
              mb="8px"
              paddingTop={"8px"}
              height={28}
              display="flex"
              alignItems="center"
            />
          </Box>
          <Button
            sx={{
              mt: 0,
              textTransform: "none",
              background: "#e6eff2",
              color: "#414B55",
              padding: "3px 16px",
              fontSize: "13px",
              fontWeight: 400,
              "&:hover": {
                background: "#e6eff2",
              },
            }}
            onClick={showChangePassodal}
          >
            Change Password
          </Button>
        </Box>
      </Box>

      {/* bottom bar  */}
      <Box
        height={29}
        sx={{
          borderTop: "1px solid #c6d4e080",
          padding: "9px 16px 9px 0",
          boxSizing: "content-box",
          // mr: "-14px",
        }}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        // mt={"-11px"}
      >
        <Box
          display="flex"
          justifyContent={"end"}
          sx={{
            "& .Mui-disabled": {
              borderColor: "#0000001f !important",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "#c6d4e066",
              borderRadius: "100px",
              border: "1px solid #c6d4e000",
              marginRight: "8px",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              color: "#414b55",
              textTransform: "none",
              width: 85,
              boxShadow:
                "0px 3px 1px -3px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.12)",
              "&:hover": {
                backgroundColor: "rgba(198, 212, 224, 0.4)",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
              },
            }}
            onClick={() => {}}
            disabled={false}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#2993d1",
              border: "1px solid #2993d1",
              color: "#fff",
              fontFamily: "Verdana,Arial,sans-serif",
              fontSize: "13px",
              padding: "0px 13px",
              borderRadius: "100px",
              textTransform: "none",
              minWidth: "92px",
              marginRight: "8px",
              width: 120,
              "&:hover": {
                backgroundColor: "#2993d1",
              },
            }}
            type="submit"
            disabled={false}
          >
            Apply
          </Button>
        </Box>
      </Box>
      <ChangePasswordModal
        popupVisible={changePassModalVisible}
        hide={hideChangePassModal}
        handleUpdatePassword={handleUpdatePassword}
      />
      <CustomPopover
        id="email_info"
        contnent={
          "We will send a message to this email account when you forget your password or cannot sign in to Eemaan Dashboard. If you are unable to change this email, please contact Eemaan TechSupport."
        }
      />
    </Box>
  );
};
export default Account;
