import { memo, useCallback, useState } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import * as yup from "yup";

import styles from "./styles.module.scss";
import { Visibility, VisibilityOff, Warning } from "@material-ui/icons";
import CustomTextField from "../../../Components/CustomTextField";
import { useFormik } from "formik";
import CustomLabel from "../../../Components/customLabel";

export const ChangePasswordModal = ({
  popupVisible,
  hide,
  handleUpdatePassword,
}: any) => {
  const [visibleEye, setVisibleEye] = useState({
    currentPass: false,
    newPass: false,
    confirmPass: false,
  });

  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

  const validationSchema = yup.object({
    currentPassword: yup.string().required("required"),
    newPassword: yup
      .string()
      .matches(passwordRules, { message: "Please create a stronger password" }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), ""], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUpdatePassword({
        oldPassword: values.currentPassword,
        newPassword: values.confirmPassword,
      });
      setTimeout(() => {
        formik.resetForm();
      }, 2000);
    },
  });

  const [width, setWidth] = useState(418);
  const [height, setHeight] = useState(240);

  const handleResizeEnd = useCallback(
    ({ height, width }: any) => {
      setHeight(height);
      setWidth(width);
    },
    [setWidth, setHeight]
  );
  const { currentPassword, confirmPassword, newPassword } = formik.values;
  const isDisabled = !currentPassword || !confirmPassword || !newPassword;

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        formik.handleSubmit(e);
      }}
    >
      <Popup
        id="change_passowrd_popup"
        width={width}
        height={height}
        visible={popupVisible}
        onHiding={hide}
        hideOnOutsideClick={true}
        showCloseButton={true}
        resizeEnabled={false}
        dragEnabled
        title="Change Password"
        wrapperAttr={{
          id: "change_password",
          class: styles.change_password_wrapper,
        }}
        enableBodyScroll={false}
        deferRendering={true}
        onResizeEnd={handleResizeEnd}
        onVisibleChange={(value: any) => console.log("value", value)}
      >
        <Box width={"100%"} display={"flex"}>
          <Box width={"40%"} height={100}>
            <CustomLabel
              label="Current Password:"
              mb="8px"
              height={28}
              display="flex"
              alignItems="center"
            />
            <CustomLabel
              label="New Password:"
              mb="8px"
              height={28}
              display="flex"
              alignItems="center"
            />
            <CustomLabel
              label="Confirm Password:"
              mb="8px"
              height={28}
              display="flex"
              alignItems="center"
            />
          </Box>
          {/* left  */}
          <Box
            width={"60%"}
            height={100}
            // border="1px solid red"
            display={"flex"}
            flexDirection={"column"}
            alignItems={"end"}
          >
            <Box
              mb={"8px"}
              width={208}
              sx={{
                "& .MuiInputBase-input": {
                  paddingRight: 0,
                },
              }}
            >
              <CustomTextField
                errorMessage={
                  formik.touched.currentPassword &&
                  (formik.errors.currentPassword as any)
                }
                props={{
                  sx: {
                    paddingRight: 0,
                  },
                  name: "currentPassword",
                  type: visibleEye.currentPass ? "text" : "password",
                  fullWidth: true,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={Boolean(formik.errors.currentPassword)}
                          size="small"
                          onClick={() =>
                            setVisibleEye((pre) => ({
                              ...pre,
                              currentPass: !pre.currentPass,
                            }))
                          }
                          sx={{ p: 0, mr: "-3px" }}
                        >
                          {visibleEye.currentPass ? (
                            <Visibility
                              sx={{ fontSize: 16, color: "#414b55" }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{ fontSize: 16, color: "#414b55" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                  inputProps: {
                    maxLength: 19,
                  },

                  value: formik.values.currentPassword,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.currentPassword &&
                    Boolean(formik.errors.currentPassword),
                  helperText: formik.touched.currentPassword && (
                    <Warning
                      sx={{ fontSize: 20, mt: "4px" }}
                      className="error_icon"
                    />
                  ),
                }}
              />
            </Box>
            <Box
              mb={"8px"}
              width={208}
              sx={{
                "& .MuiInputBase-input": {
                  paddingRight: 0,
                },
              }}
            >
              <CustomTextField
                errorMessage={
                  formik.touched.newPassword &&
                  (formik.errors.newPassword as any)
                }
                props={{
                  sx: {
                    paddingRight: 0,
                  },
                  name: "newPassword",
                  type: visibleEye.newPass ? "text" : "password",
                  fullWidth: true,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={Boolean(formik.errors.newPassword)}
                          size="small"
                          onClick={() =>
                            setVisibleEye((pre) => ({
                              ...pre,
                              newPass: !pre.newPass,
                            }))
                          }
                          sx={{ p: 0, mr: "-3px" }}
                        >
                          {visibleEye.newPass ? (
                            <Visibility
                              sx={{ fontSize: 16, color: "#414b55" }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{ fontSize: 16, color: "#414b55" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                  inputProps: {
                    maxLength: 19,
                  },

                  value: formik.values.newPassword,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword),
                  helperText: formik.touched.newPassword && (
                    <Warning
                      sx={{ fontSize: 20, mt: "4px" }}
                      className="error_icon"
                    />
                  ),
                }}
              />
            </Box>
            <Box
              mb={"8px"}
              width={208}
              sx={{
                "& .MuiInputBase-input": {
                  paddingRight: 0,
                },
              }}
            >
              <CustomTextField
                errorMessage={
                  formik.touched.confirmPassword &&
                  (formik.errors.confirmPassword as any)
                }
                props={{
                  sx: {
                    paddingRight: 0,
                  },
                  name: "confirmPassword",
                  type: visibleEye.confirmPass ? "text" : "password",
                  fullWidth: true,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={Boolean(formik.errors.confirmPassword)}
                          size="small"
                          onClick={() =>
                            setVisibleEye((pre) => ({
                              ...pre,
                              confirmPass: !pre.confirmPass,
                            }))
                          }
                          sx={{ p: 0, mr: "-3px" }}
                        >
                          {visibleEye.confirmPass ? (
                            <Visibility
                              sx={{ fontSize: 16, color: "#414b55" }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{ fontSize: 16, color: "#414b55" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                  inputProps: {
                    maxLength: 19,
                  },

                  value: formik.values.confirmPassword,
                  onChange: formik.handleChange,
                  error:
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword),
                  helperText: formik.touched.confirmPassword && (
                    <Warning
                      sx={{ fontSize: 20, mt: "4px" }}
                      className="error_icon"
                    />
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>

        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          children={
            <Box>
              <Button
                variant="contained"
                sx={{
                  background: "#c6d4e066",
                  borderRadius: "100px",
                  border: "1px solid #c6d4e000",
                  marginRight: "8px",
                  fontFamily: "Verdana,Arial,sans-serif",
                  fontSize: "13px",
                  padding: "0px 13px",
                  color: "#414b55",
                  textTransform: "none",
                  width: 85,
                  boxShadow:
                    "0px 3px 1px -3px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.12)",
                  "&:active": {
                    borderColor: "transparent !important",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(198, 212, 224, 0.4)",
                    boxShadow:
                      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                  },
                }}
                onClick={hide}
                disabled={false}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "#2993d1",
                  border: "1px solid #2993d1",
                  color: "#fff",
                  fontFamily: "Verdana,Arial,sans-serif",
                  fontSize: "13px",
                  padding: "0px 13px",
                  borderRadius: "100px",
                  textTransform: "none",
                  minWidth: "92px",
                  width: 120,
                  borderColor: "transparent !important",
                  "& .Mui-disabled": {
                    borderColor: "transparent !important",
                  },

                  "&:hover": {
                    backgroundColor: "#2993d1",
                  },
                }}
                type="submit"
                disabled={isDisabled}
                onClick={() => formik.handleSubmit(formik.values as any)}
              >
                Apply
              </Button>
            </Box>
          }
        />
      </Popup>
    </Box>
  );
};

export default memo(ChangePasswordModal);
