import { memo, useCallback, useState } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { Box, Button, CircularProgress } from "@mui/material";
import styles from "./styles.module.scss";
import { useMainCtx } from "../../redux/store/useMainCtx";
import useViewLogsPopup from "../ExportPage/hooks/useViewLogsPopup";

export const ViewLogsPopup = ({ popupVisible, hide, isImport }: any) => {
  const {
    handleDownload,
    isLoading,
    handleDownloadAll,
    isBulkLoading,
    handleView,
    isViewLoading,
  } = useViewLogsPopup();
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(310);

  const { exportValues, importValues } = useMainCtx((state) => {
    return {
      exportValues: state.exportValues,
      importValues: state.importValues,
    };
  });

  const urls = isImport
    ? importValues?.output
    : exportValues
    ? exportValues?.output
    : {};

  const filteredUrls = urls
    ? Object.keys(urls)
        .filter((key) => key?.includes("url"))
        .reduce((obj: any, key) => {
          obj[key] = urls[key];
          return obj;
        }, {})
    : {};

  const handleResizeEnd = useCallback(
    ({ height, width }: any) => {
      setHeight(height);
      setWidth(width);
    },
    [setWidth, setHeight]
  );

  const zipFilesOnly: any = Object.fromEntries(
    Object.entries(filteredUrls).filter(([_, uri]) =>
      (uri as string)?.endsWith(".zip")
    )
  );
  const allUrls = Object.keys(zipFilesOnly).map(
    (key: any) => zipFilesOnly[key]
  );

  const sortfilteredUrls: any = {
    "report-file-url": filteredUrls["report-file-url"],
    ...Object.fromEntries(
      Object.entries(filteredUrls).filter(([key]) => key !== "report-file-url")
    ),
  };

  return (
    <Box className={styles.view_logs_wrapper}>
      <Popup
        id="popup_canvas"
        // container={"#execute_container"}
        width={width}
        height={height}
        visible={popupVisible}
        onHiding={hide}
        hideOnOutsideClick={true}
        showCloseButton={true}
        resizeEnabled={false}
        wrapperAttr={{
          id: "view_logs",
          class: styles.view_logs_wrapper,
        }}
        dragEnabled
        title="View Logs"
        enableBodyScroll={false}
        deferRendering={true}
        onResizeEnd={handleResizeEnd}
        onVisibleChange={(value: any) => console.log("value", value)}
      >
        {/* this will be become a canvs as well  */}
        <Box
          sx={{
            "& :last-child": {
              mb: 0,
            },
            height: isImport ? "" : "100%",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={"6px"}
          >
            <Box
              sx={{
                fontSize: "13px",
                fontWeight: 700,
                color: "#414B55",
              }}
            >
              Name
            </Box>
            <Box
              sx={{
                fontSize: "13px",
                fontWeight: 700,
                color: "#414B55",
              }}
              width={63}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
              pr={"2px"}
            >
              Actions
            </Box>
          </Box>
          {sortfilteredUrls &&
            Object.keys(sortfilteredUrls)
              .filter((item) => item !== "md-file-url")
              .sort((a: any, b: any) => a - b)
              .map((key, index) => {
                const url = sortfilteredUrls[key];
                if (sortfilteredUrls[key]?.endsWith(".zip")) {
                  const pathParts = sortfilteredUrls[key]?.split("/");
                  pathParts[pathParts.length - 1] = `${key.replace(
                    /-url$/,
                    ""
                  )}.zip`;
                  sortfilteredUrls[key] = pathParts.join("/");
                }
                const fileName = sortfilteredUrls[key]?.split("/").pop();

                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    // mb={

                    //     ? "6px"
                    //     : "0px"
                    // }
                    height={22}
                    sx={{
                      marginBottom: "6px",
                      "&:nth-last-child": { marginBottom: "0px !important" },
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "13px",
                        textTransform: "lowercase",
                      }}
                    >
                      {fileName}
                    </Box>{" "}
                    {isLoading[index] ? (
                      <Box
                        width={64}
                        height={22}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        //   sx={{
                        //     border: "1px solid #2993d1",
                        //     borderRadius: "4px",
                        //   }}
                      >
                        <CircularProgress
                          size="12px"
                          sx={{ color: "#057FEB !important", strokeWidth: 5 }}
                        />
                      </Box>
                    ) : (
                      <Box display={"flex"} gap={1}>
                        {fileName?.includes(".html") && (
                          <>
                            {isViewLoading ? (
                              <Box
                                width={64}
                                height={22}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                //   sx={{
                                //     border: "1px solid #2993d1",
                                //     borderRadius: "4px",
                                //   }}
                              >
                                <CircularProgress
                                  size="12px"
                                  sx={{
                                    color: "#2993D1 !important",
                                    strokeWidth: 5,
                                  }}
                                />
                              </Box>
                            ) : (
                              <Button
                                variant="text"
                                sx={{
                                  padding: 0,
                                  height: "22px",
                                  textTransform: "none",
                                  fontSize: "13px",
                                  color: "#2993D1",
                                  "&:hover": { background: "transparent" },
                                }}
                                onClick={() => handleView(url, index)}
                              >
                                View
                              </Button>
                            )}
                          </>
                        )}
                        <Button
                          variant="text"
                          sx={{
                            padding: 0,
                            height: "22px",
                            textTransform: "none",
                            fontSize: "13px",
                            color: "#2993D1",
                            "&:hover": { background: "transparent" },
                          }}
                          onClick={() => handleDownload(url, index)}
                          disabled={
                            isLoading[index] || isBulkLoading || isViewLoading
                          }
                        >
                          Download
                        </Button>
                      </Box>
                    )}
                  </Box>
                );
              })}
        </Box>
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          // options={bookButtonOptions}

          children={
            <Box display={"flex"} justifyContent={"end"}>
              {isBulkLoading ? (
                <Box
                  width={64}
                  height={22}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  // sx={{
                  //   border: "1px solid #2993d1",
                  //   borderRadius: 17,
                  // }}
                >
                  <CircularProgress
                    size="12px"
                    sx={{ color: "#057FEB !important", strokeWidth: 5 }}
                  />
                </Box>
              ) : (
                <Button
                  variant="text"
                  sx={{
                    padding: "4px 18px",
                    textTransform: "none",
                    fontSize: "13px",
                    borderRadius: 17,
                    height: 22,
                    background: "#2993d1",
                    color: "white",
                    lineHeight: "1px",
                    mr: "1px",
                    "&:hover": {
                      borderColor: "#2993d1",
                      backgroundColor: "#2993d1",
                    },
                  }}
                  onClick={() => handleDownloadAll(allUrls)}
                >
                  Download All ZIPs
                </Button>
              )}
            </Box>
          }
        />
      </Popup>
    </Box>
  );
};

export default memo(ViewLogsPopup);
