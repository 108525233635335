import { Box, CircularProgress, Typography } from "@mui/material";

const Loading = () => {
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "11px",
          width: "117px",
          height: "32px",
          border: "1px solid rgba(198, 212, 224, 0.7)",
          boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
          borderRadius: "3px",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          size="14px"
          sx={{ color: "#2993D1 !important", strokeWidth: 5 }}
        />
        <Typography sx={{ fontSize: 13, color: "#414b55" }}>
          Loading...
        </Typography>
      </Box>
    </Box>
  );
};
export default Loading;
