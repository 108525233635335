import { memo, useCallback, useState } from "react";
import { Popup } from "devextreme-react/popup";
import { Box, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { appVersion } from "../../utils";

export const AboutModal = ({ popupVisible, hide }: any) => {
  const [width, setWidth] = useState(522);
  const [height, setHeight] = useState(220);

  const handleResizeEnd = useCallback(
    ({ height, width }: any) => {
      setHeight(height);
      setWidth(width);
    },
    [setWidth, setHeight]
  );

  return (
    <Box className={styles.about_modal_wrapper}>
      <Popup
        id="about_popup"
        wrapperAttr={{
          id: "about_modal",
          class: styles.about_modal_wrapper,
        }}
        width={width}
        height={height}
        visible={popupVisible}
        onHiding={hide}
        hideOnOutsideClick={true}
        showCloseButton={true}
        resizeEnabled={false}
        dragEnabled={false}
        // title="View Logs"
        enableBodyScroll={false}
        deferRendering={true}
        onResizeEnd={handleResizeEnd}
        onVisibleChange={(value: any) => console.log("value", value)}
      >
        <Box mt="-32px" position={"absolute"} top={"52px"}>
          <Typography
            variant="h6"
            sx={{ color: "#414b55", fontWeight: "bold", mb: "4px" }}
          >
            Eemaan Dashboard
          </Typography>
          <Typography sx={{ fontSize: "13px", color: "#414b55" }}>
            Version {appVersion}
          </Typography>
        </Box>
        <Box
          mt="111px"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "13px", color: "#414b55" }}>
            Copyright © 2024 Eemaan Limited. All rights reserved.
          </Typography>
          <img src="/assets/icons/logo.svg" alt="Logo" height={24} style={{}} />
        </Box>
      </Popup>
    </Box>
  );
};

export default memo(AboutModal);
