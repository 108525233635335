import { useCallback, useState } from "react";
import { useMainCtx } from "../../../../redux/store/useMainCtx";
import { useShallow } from "zustand/react/shallow";

const useDisplayPreferences = () => {
  const {
    setGlobalDateFormate,
    dateFormate,
    setGlobalTimeFormate,
    timeFormate,
    setGlobalDesktopIconSize,
    desktopIconSize,
    desktopBgColor,
    backgroundType,
    isCustomizeBg,
    backgroundSize,
    setBackgroundType,
    setIsCustomizeBg,
    setBackgroundSize,
    bgImage,
    setGlobalDesktopBgColor,
    desktopTextColor,
    setGlobalDesktopTextColor,
  } = useMainCtx(
    useShallow((state) => {
      return {
        dateFormate: state.dateFormate,
        timeFormate: state.timeFormate,
        setGlobalDateFormate: state.setGlobalDateFormate,
        setGlobalTimeFormate: state.setGlobalTimeFormate,
        setGlobalDesktopIconSize: state.setGlobalDesktopIconSize,
        desktopIconSize: state.desktopIconSize,
        desktopBgColor: state.desktopBgColor,
        backgroundType: state.backgroundType,
        setBackgroundType: state.setBackgroundType,
        setIsCustomizeBg: state.setIsCustomizeBg,
        isCustomizeBg: state.isCustomizeBg,
        backgroundSize: state.backgroundSize,
        setBackgroundSize: state.setBackgroundSize,
        bgImage: state.bgImage,
        setGlobalDesktopBgColor: state.setGlobalDesktopBgColor,
        desktopTextColor: state.desktopTextColor,
        setGlobalDesktopTextColor: state.setGlobalDesktopTextColor,
      };
    })
  );

  const [uploadImageModalVisible, setUploadImageModalVisible] = useState(false);

  // const [dateFormate, setDateFormat] = useState("YYYY-MM-dd (System setting)");
  // const [timeFormate, setTimeDateFormat] = useState(
  //   "24-hour time (System setting)"
  // );
  const [menuStyle, setMenuStyle] = useState("Fullscreen");
  // const [iconSize, setIconSize] = useState("Normal (64×64)");
  // const [isCustomizeBg, setIsCustomizeBg] = useState(true);
  // const [backgroundType, setBackgroundType] = useState("Image");
  // const [backgroundSize, setBackgroundSize] = useState("Fill");

  const handleApply = () => {};

  // updateBodyStyle(false, "/assets/icons/view_canvas_wallpaper.jpg");
  const hideUploadImageModal = useCallback(() => {
    setUploadImageModalVisible(false);
  }, [setUploadImageModalVisible]);

  const showUploadImageModal = useCallback(() => {
    setUploadImageModalVisible(true);
  }, [setUploadImageModalVisible]);

  const handleBgColorChange = useCallback(
    (color: string) => {
      // setColor(value);
      setGlobalDesktopBgColor(color);
    },
    [setGlobalDesktopBgColor]
  );

  const handleTextColorChange = (color: string) => {
    // setColor(value);
    setGlobalDesktopTextColor(color);
  };

  return {
    setDateFormat: setGlobalDateFormate,
    setTimeDateFormat: setGlobalTimeFormate,
    setMenuStyle,
    setIconSize: setGlobalDesktopIconSize,
    setIsCustomizeBg,
    setBackgroundType,
    setBackgroundSize,
    backgroundSize,
    backgroundType,
    isCustomizeBg,
    iconSize: desktopIconSize,
    menuStyle,
    dateFormate,
    timeFormate,
    handleApply,
    desktopBgColor,
    hideUploadImageModal,
    showUploadImageModal,
    uploadImageModalVisible,
    bgImage,
    handleBgColorChange,
    desktopTextColor,
    handleTextColorChange,
  };
};

export default useDisplayPreferences;
