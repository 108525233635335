import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useMainCtx } from "../../redux/store/useMainCtx";

const FileModal = () => {
  const { setFolders } = useMainCtx();

  const [open, setOpen] = useState(true);
  const [fileName, setFileName] = useState("not available!");
  const [jsonData, setJsonData] = useState<any>(null);

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const fileContent = e.target.result;
      const jsonData = JSON.parse(fileContent);
      setJsonData(jsonData);
    };
    setFileName(file.name);

    reader.readAsText(file);
  };

  const handleOk = () => {
    if (jsonData) {
      setFolders(
        jsonData?.map((item: any) => ({
          ...item,
          id: item.pageId,
          isOpen: false,
          folderName: item.title,
        }))
      );
    }
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        sx={{
          border: "2px solid rgb(0, 0, 0)",
          margin: 50,
          background: "rgb(18, 18, 18)",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px",
          borderRadius: 2,
        }}
      >
        <>
          <Box
            sx={{
              //   border: "7px solid green",
              margin: "20px",
              "& .p": {
                color: "#fff",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                padding: "40px",
                border: "1px dashed",
                color: "white",
                fontWeight: "bold",
                marginBottom: 2,
                borderRadius: 2,
              }}
            >
              {fileName}
            </Typography>
            <input
              accept=".json"
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
              onChange={handleFileUpload}
              disabled={Boolean(jsonData)}
            />
            <Box display={"flex"} alignItems={"center"}>
              <label htmlFor="contained-button-file">
                <Button
                  component="span"
                  disabled={Boolean(jsonData)}
                  sx={{
                    padding: "12px 22px",
                    minWidth: 12,
                    background: "#008000",
                    borderRadius: 2,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    color: "white",
                    "&:hover": {
                      background: "#058e05",
                    },
                  }}
                >
                  Upload Json
                </Button>
              </label>

              <Button
                disabled={!Boolean(jsonData)}
                onClick={handleOk}
                sx={{
                  padding: "10px 22px",
                  minWidth: 12,
                  borderRadius: 2,
                  background: "#008000",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  fontSize: "14px",
                  color: "white",
                  marginLeft: 2,
                  "&:hover": {
                    background: "#058e05",
                  },
                }}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default FileModal;
