import React from "react";
import styles from "./contextMenu.module.scss";
import { ContextMenu, ContextMenuItem } from "rctx-contextmenu";
import closeIco from "../../assets/images/close-red-icon.svg";
import tollIco from "../../assets/images/tool_ico.svg";
import clopyIco from "../../assets/images/copy_ico.svg";

const ContextMenuOptions: React.FC<{
  onClose: () => void;
}> = ({ onClose = () => {} }) => {
  return (
    <div>
      {/* disabled context menu */}
      <ContextMenu id="unique_identifier-1">
        <ContextMenuItem onClick={onClose} className={styles.context_item}>
          <img src={closeIco} alt="Delete Icon" width={18} height={18} />
          Close
        </ContextMenuItem>
        <ContextMenuItem
          attributes={{ item: "Copy" }}
          disabled
          onClick={() => {}}
          className={styles.context_item}
        >
          <img src={clopyIco} alt="Delete Icon" width={18} height={18} />
          Copy
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {}}
          disabled
          className={styles.context_item}
        >
          <img src={tollIco} alt="Delete Icon" width={18} height={18} />
          Rename
        </ContextMenuItem>
      </ContextMenu>
    </div>
  );
};

export default ContextMenuOptions;
