import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { updatePassword, type UpdatePasswordInput } from "aws-amplify/auth";

const useAccount = () => {
  const [changePassModalVisible, setChangePassModalVisible] = useState(false);

  const hideChangePassModal = useCallback(() => {
    setChangePassModalVisible(false);
  }, [setChangePassModalVisible]);

  const showChangePassodal = useCallback(() => {
    setChangePassModalVisible(true);
  }, [setChangePassModalVisible]);

  const handleUpdatePassword = async ({
    oldPassword,
    newPassword,
  }: UpdatePasswordInput) => {
    try {
      await updatePassword({ oldPassword, newPassword });
      toast.success("Successfully update password", {
        autoClose: 2000,
      });
      hideChangePassModal();
    } catch (err: any) {
      toast.error(err.message, {
        autoClose: 2000,
      });
    }
  };

  return {
    changePassModalVisible,
    showChangePassodal,
    hideChangePassModal,
    handleUpdatePassword,
  };
};

export default useAccount;
