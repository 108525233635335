/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import "./styles.module.scss";
interface CustomSelectProps {
  options: { label: string; value: string }[] | any[];
  disabled?: boolean;
  props: TextFieldProps;
  isStringArray?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  disabled,
  props,
  isStringArray,
}) => {
  return (
    <TextField
      {...props}
      disabled={disabled}
      select
      fullWidth
      size="small"
      variant="outlined"
      sx={{
        "& .error_icon": {
          display: !props.error ? "none" : "",
        },

        "& .MuiSelect-select": {
          padding: "3px 11px",
          fontSize: 13,
          fontFamily: "Verdana,Arial,sans-serif",
          color: "#414B55",
          height: 22,
          outline: "none",
          lineHeight: "21px",
        },
        "& .MuiFormHelperText-root": {
          position: "absolute",
          left: "-18px",
          fontSize: "13px",
          marginTop: "2px",
          marginLeft: 0,
          marginRight: 0,
        },
        "&.Mui-disabled fieldset": {
          borderColor: "#c6d4e0b3 !important",
        },

        "& .MuiInputBase-root": {
          background: props.error ? "#fff3f2" : "",
          "& fieldset": {
            borderColor: "#c6d4e0b3 !important",
          },
          "&:hover fieldset": {
            borderColor: props.error ? "#d32f2f" : "#c6d4e0b3",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#2993D1",
            borderWidth: "1px",
          },
        },
        "& .MuiMenuItem-root": {
          fontSize: "13px !important",
          fontFamily: "Verdana,Arial,sans-serif",
          color: "#414B55 !important",
        },
      }}
    >
      {isStringArray &&
        options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      {!isStringArray &&
        options.map((option) => (
          <MenuItem key={option?.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default CustomSelect;
