import { Box } from "@mui/material";
import { useCallback } from "react";
import styles from "./styles.module.scss";
import { ColorBox } from "devextreme-react";
import { ColorBoxTypes } from "devextreme-react/cjs/color-box";

interface ColorPickerProps {
  disabled: boolean;
  handleBgColorChange: any;
  color?: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  disabled,
  handleBgColorChange,
  color,
}) => {
  const handleColorChange = useCallback(
    ({ value }: ColorBoxTypes.ValueChangedEvent) => {
      handleBgColorChange(value);
    },
    []
  );
  return (
    <Box className={styles.color_picker_wrapper}>
      <ColorBox
        disabled={disabled}
        value={color}
        applyValueMode="useButtons"
        inputAttr={{
          "aria-label": "Event Handling",
          // id: "color_picker_wrapper",
          // class: styles.color_picker_wrapper,
        }}
        onValueChanged={handleColorChange}
      />
    </Box>
  );
};

export default ColorPicker;
