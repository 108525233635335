/* eslint-disable import/no-anonymous-default-export */
import { fetchAuthSession, JWT } from "aws-amplify/auth";
import { API_URL, apiName } from "../utils";
import { toast } from "react-toastify";
import HttpClient from "./httpClient";

// export const getAuthenticationToken = async (): Promise<
//   string | null | JWT
// > => {
//   try {
//     const session = (await fetchAuthSession()).tokens?.accessToken;
//     return session || null;
//   } catch (error) {
//     console.error("Error fetching authentication token:", error);
//     return null;
//   }
// };

class Http {
  public async getAuthenticationToken(): Promise<string | null | JWT> {
    try {
      const session = (await fetchAuthSession()).tokens?.accessToken;
      return session || null;
    } catch (error) {
      console.error("Error fetching authentication token:", error);
      return null;
    }
  }

  public fetchFromAmplify = async <T>(url: string): Promise<T | undefined> => {
    const apiClient = new HttpClient(apiName);
    try {
      const data = await apiClient.get<T>(url);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  public fetcher = (...args: [RequestInfo, RequestInit?]): Promise<any> =>
    fetch(...args).then((res) => res.json());

  public apiRequest = async (
    url: string,
    method: string,
    body?: any,
    isFile?: boolean
  ) => {
    const token = await this.getAuthenticationToken();
    const headers = {
      Accept: isFile ? "application/octet-stream" : "*/*",
      Authorization: `Bearer ${token?.toString()}`,
      "Content-Type": "application/json",
    };

    const options: RequestInit = {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    };

    const response = await fetch(`${API_URL.BASE_URL + url}`, options);

    if (!response.ok) {
      let errorMessage = "An error occurred while fetching data";
      try {
        const errorResponse = await response.json();
        errorMessage = errorResponse.message || JSON.stringify(errorResponse);
      } catch (e) {
        console.error("Error parsing the error response:", e);
      }
      const error = new Error(errorMessage);
      (error as any).status = response.status;
      console.error("error.message", error);

      setTimeout(() => {
        toast.error(error.message, { autoClose: 2000 });
      }, 100);

      throw error;
    }

    return response.json();
  };

  public downloadFile = async (apiUrl: string) => {
    const token = await this.getAuthenticationToken();

    const headers = {
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const fileName = apiUrl.split("/").pop();
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        // window.open(url, "_blank");
        // console.log("url", url);
        const link: any = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        // Append the link to the body (temporarily)
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      } else {
        toast.error(response.statusText, { autoClose: 2000 });
        console.error("Failed to download file:", response);
      }
    } catch (error: any) {
      toast.error(error.message, { autoClose: 2000 });
      console.error("Error downloading file:", error);
    }
  };

  public previewFile = async (apiURl: string) => {
    const token = await this.getAuthenticationToken();
    const headers = {
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await fetch(apiURl, {
        method: "GET",
        headers: headers,
      });
      const htmlContent = await response.text();

      // const htmlContent = await response.blob();

      const blob = new Blob([htmlContent], { type: "text/html" });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL, "_blank");
    } catch (error: any) {
      toast.error(error.message, { autoClose: 2000 });
    }
  };

  public downloadAllFiles = async (apiUrls: string[]) => {
    const token = await this.getAuthenticationToken();
    const headers = {
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      // Create an array of fetch promises for each URL
      const downloadPromises = apiUrls.map(async (apiUrl) => {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: headers,
        });

        if (response.ok) {
          const fileName = apiUrl.split("/").pop();
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          // Create a download link and trigger it
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName || "downloadedFile");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Clean up the URL object after download
          window.URL.revokeObjectURL(url);
        } else {
          console.error(
            `Failed to download file from ${apiUrl}:`,
            response.statusText
          );
        }
      });

      // Wait for all downloads to complete
      await Promise.all(downloadPromises);
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };
}

export default new Http();
