import { Box } from "@mui/material";
import CustomLabel from "./customLabel";

interface TopHeadingProps {
  title: string;
}

const TopHeading: React.FC<TopHeadingProps> = ({ title }) => {
  return (
    <Box
      height={56}
      padding={"0px 40px"}
      sx={{
        backgroundColor: "#2993D1",
        display: "flex",
        alignItems: "center",
        // mt: "-1px",
      }}
    >
      <CustomLabel
        label={title}
        mb="4px"
        fontWeight={600}
        fontSize={16}
        color="#fff"
      />
    </Box>
  );
};

export default TopHeading;
