import { create } from "zustand";
import { TFolder } from "../../Pages/PageContainer/pagecontainer";
import { applyBackgroundSettings, getBackgroundStyle } from "../../utils";

type MainStore = {
  data: any;
  folders: TFolder[];
  elements: any[];
  // isLoading: boolean;
  dataInitialized: boolean;
  // isSocketChange: boolean;
  weatherData: any;
  setFolders: (folders: TFolder[]) => void;
  setDataInitialized: (value: boolean) => void;
  setData: (value: any) => void;
  updateData: (value: any) => void;
  setElements: (elemts: any[]) => void;
  setWeatherData: (obj: any) => void;
  // toggleSocketChange: (value: boolean) => void;
  exportValues: any;
  setExportValues: (obj: any) => void;

  session: boolean;
  toggleSession: (value: boolean) => void;
  progress: number;
  setProgress: (value: number) => void;
  updateExportValues: (value: any) => void;
  updateImportValues: (value: any) => void;
  importValues: any;
  setImportValues: (value: any) => void;
  exportScriptList: string[];
  setExportScriptList: (value: string[]) => void;

  importScriptList: string[];
  setImportScriptList: (value: string[]) => void;
  step: number;
  userDetails: any;
  setStep: (value: number) => void;
  handleNext: () => void;
  handleBack: () => void;

  updateFolders: (value: any) => void;
  setUserDetails: (value: any) => void;

  dateFormate: string;
  timeFormate: string;
  desktopIconSize: string;
  desktopBgColor: string;
  backgroundType: string;
  isCustomizeBg: boolean;
  backgroundSize: string;
  bgImage: string;
  selectedImage: {
    title: string;
    url: string;
  } | null;

  desktopTextColor: string;
  isWindow: boolean;
  shortcutFolders: any[];

  setGlobalDateFormate: (value: string) => void;
  setGlobalTimeFormate: (value: string) => void;
  setGlobalDesktopIconSize: (value: string) => void;
  setGlobalDesktopBgColor: (value: string) => void;
  setBackgroundType: (value: string) => void;
  setIsCustomizeBg: (value: boolean) => void;
  setBackgroundSize: (value: string) => void;
  setGlobalBgImage: (value: string) => void;
  setSelectedImage: (
    value: {
      title: string;
      url: string;
    } | null
  ) => void;
  setGlobalDesktopTextColor: (value: string) => void;
  setWindow: (value: any) => void;
  setShortcutFolders: (value: any) => void;
};

export const useMainCtx = create<MainStore>((set, get) => {
  return {
    elements: [],
    folders: [],
    data: [],
    weatherData: {},
    dataInitialized: false,
    session: false,
    exportScriptList: [],
    importScriptList: [],
    // isSocketChange: false,
    // isLoading: false,
    // toggleSocketChange: (value: boolean) =>
    //   set((_) => ({ isSocketChange: value })),

    exportValues: {},
    importValues: {},
    progress: 0,

    step: 1,
    userDetails: {},
    dateFormate: "YYYY-MM-dd (System setting)",
    timeFormate: "24-hour time (System setting)",
    desktopIconSize: "Normal (64×64)",
    desktopBgColor: "#2993D1",
    backgroundType: "Image",

    isCustomizeBg: true,

    backgroundSize: "Fill",
    bgImage:
      "https://img.freepik.com/free-vector/abstract-red-color-technology-line-wave-background_1142-9003.jpg?t=st=1731921845~exp=1731925445~hmac=1d3bfa0f997fdc4ccb651df1c891a94e066c8ddcf7dfd2fb65f529094cf7744e&w=2000",

    selectedImage: null,
    desktopTextColor: "#ffffff",
    isWindow: false,

    shortcutFolders: [],
    setShortcutFolders: (value: any) => {
      set((_) => ({
        isWindow: value,
      }));
    },

    setWindow: (value: any) => {
      set((_) => ({
        isWindow: value,
      }));
    },
    setGlobalDesktopTextColor: (value: string) => {
      set((_) => ({
        desktopTextColor: value,
      }));
    },

    setSelectedImage: (
      value: {
        title: string;
        url: string;
      } | null
    ) => {
      set((_) => ({
        selectedImage: value,
      }));
    },
    setGlobalBgImage: (value: string) => {
      applyBackgroundSettings(
        get().backgroundType,
        get().desktopBgColor,
        getBackgroundStyle(get().backgroundSize),
        value
      );
      set((_) => ({
        bgImage: value,
      }));
    },
    setBackgroundSize: (value: string) => {
      if (get().backgroundType === "Image") {
        applyBackgroundSettings(
          get().backgroundType,
          get().desktopBgColor,
          getBackgroundStyle(value),
          get().bgImage
        );
      }
      set((_) => ({
        backgroundSize: value,
      }));
    },

    setIsCustomizeBg: (value: boolean) => {
      set((_) => ({
        isCustomizeBg: value,
      }));
    },

    setBackgroundType(value) {
      applyBackgroundSettings(value, get().desktopBgColor, {}, get().bgImage);
      set((_) => ({
        backgroundType: value,
      }));
    },
    setGlobalDesktopBgColor: (value: string) => {
      if (get().backgroundType === "Color") {
        document.body.style.backgroundColor = value;
      }
      set((_) => ({
        desktopBgColor: value,
      }));
    },
    setGlobalDesktopIconSize: (value: string) => {
      set((_) => ({
        desktopIconSize: value,
      }));
    },

    setGlobalTimeFormate: (value: string) => {
      set((_) => ({
        timeFormate: value,
      }));
    },

    setGlobalDateFormate: (value: string) => {
      set((_) => ({
        dateFormate: value,
      }));
    },

    setUserDetails: (value: any) => {
      set((_) => ({
        userDetails: value,
      }));
    },

    handleBack: async () => {
      const _step = get().step;
      await get().setStep(_step - 1);
    },
    handleNext: async () => {
      const _step = get().step;
      await get().setStep(_step + 1);
    },
    setStep: (value: number) => {
      set((_) => ({
        step: value,
      }));
    },
    setImportScriptList: (value: any) => {
      set((_) => ({
        importScriptList: value,
      }));
    },

    setExportScriptList: (value: any) => {
      set((_) => ({
        exportScriptList: value,
      }));
    },
    setImportValues: (value: any) => {
      set((_) => ({
        importValues: value,
      }));
    },

    updateImportValues: (value: any) => {
      const _importValues = get().importValues;
      get().setImportValues({ ..._importValues, ...value });
    },

    updateExportValues: (value: any) => {
      const _exportValues = get().exportValues;
      get().setExportValues({ ..._exportValues, ...value });
    },

    setProgress: (value: number) => {
      set((_) => ({
        progress: value,
      }));
    },
    toggleSession: (value: boolean) => {
      set((_) => ({
        session: value,
      }));
    },
    setFolders: (value: TFolder[]) => {
      set((_) => ({
        folders: value,
      }));
    },

    updateFolders: async (value: any) => {
      await get().setFolders(value);
    },

    setWeatherData: (obj: any) =>
      set((_) => ({
        weatherData: obj,
      })),

    // New flag to track initialization
    setData: (newData: any) => set((_) => ({ data: newData })),
    updateData: (updatedData: any) =>
      set((_) => ({
        data: updatedData,
      })),
    setDataInitialized: (value: boolean) => set({ dataInitialized: value }),
    setElements: (value: any[]) =>
      set((_) => ({
        elements: value,
      })),

    setExportValues: (value: any) => {
      set((_) => ({
        exportValues: value,
      }));
    },
    // toggleLoading: (value: boolean) => {
    //   set((_) => ({
    //     isLoading: value,
    //   }));
    // },
  };
});
