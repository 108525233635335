import { useCallback, useEffect, useState } from "react";
import { useMainCtx } from "../../redux/store/useMainCtx";
import useSWR from "swr";
import { API_URL } from "../../utils";
import { GET_CANVAS_DATA } from "../../http/endpoints";
import { featureFlag } from "../../contants/feauture";
import SessionStorageUtil from "../../services/session";
import { signOut as authSignOut } from "@aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import http from "../../services/http";

const usePageContainer = () => {
  const {
    folders,
    setFolders,
    setWeatherData,
    exportValues,
    setProgress,
    setExportValues,
    setImportValues,
    importValues,
    setExportScriptList,
    setStep,
    updateFolders,
    setUserDetails,
    userDetails,
    dateFormate,
    timeFormate,
    desktopIconSize,
    desktopTextColor,
    setWindow,
    isWindow,
  } = useMainCtx((state) => {
    return {
      exportValues: state.exportValues,
      setWeatherData: state.setWeatherData,
      setFolders: state.setFolders,
      folders: state.folders,
      setProgress: state.setProgress,
      setExportValues: state.setExportValues,
      setImportValues: state.setImportValues,
      importValues: state.importValues,
      setExportScriptList: state.setExportScriptList,
      setStep: state.setStep,
      updateFolders: state.updateFolders,
      setUserDetails: state.setUserDetails,
      userDetails: state.userDetails,
      dateFormate: state.dateFormate,
      timeFormate: state.timeFormate,
      desktopIconSize: state.desktopIconSize,
      desktopTextColor: state.desktopTextColor,
      setWindow: state.setWindow,
      isWindow: state.isWindow,
    };
  });

  const [modalState, setModalState] = useState(true);
  const [aboutModalVisible, setAboutModalVisible] = useState(false);

  // updateBodyStyle(false, "/assets/icons/view_canvas_wallpaper.jpg");
  const hideWindowModal = useCallback(() => {
    setWindow(false);
  }, [setWindow]);

  const showWindowModal = useCallback(() => {
    setWindow(true);
  }, [setWindow]);

  const hideAboutModal = useCallback(() => {
    setAboutModalVisible(false);
  }, [setAboutModalVisible]);

  const showAboutModal = useCallback(() => {
    setAboutModalVisible(true);
  }, [setAboutModalVisible]);

  const { isLoading } = useSWR(
    !featureFlag.isLocalStates &&
      featureFlag.enableCanvasApi &&
      GET_CANVAS_DATA,
    http.fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false, // Disable retry on error
    }
  );

  const { data: weatherData } = useSWR(
    !featureFlag.isLocalStates &&
      featureFlag.enableWeatherApi &&
      "https://api.oceandrivers.com/v1.0/getWeatherDisplay/cnarenal/?period=latestdata",
    http.fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  const getFetcher = (url: string) => http.apiRequest(url, "GET");

  // get export script list
  const {
    data: exportScriptData,
    // isLoading: exportScriptLoading,
    error,
  } = useSWR(
    featureFlag.enableWeatherApi && `${API_URL.GET_EXPORT_IDS}`,
    getFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  const filteredReadData = weatherData
    ? {
        Time: weatherData.TWS_GUST_MAX.TIME_STRING,
        Temperature: weatherData?.TEMPERATURE,
        Humidity: weatherData.HUMIDITY,
        "Weather Des": weatherData.WEATHER_DES,
      }
    : null;

  const updateUserDetails = async () => {
    const { signInDetails } = await getCurrentUser();

    // const fetchDev = await fetchDevices();
    // console.log("fetchDev", fetchDev);

    setUserDetails({ username: signInDetails?.loginId });
  };

  useEffect(() => {
    setWeatherData(filteredReadData);
    updateUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherData]);

  const data = [
    {
      pageId: "13766-33766",
      isOpen: false,
      title: "Genesys Cloud Export Wizard",
      width: "700",
      height: "580",
      bgColor: "#ffffff",
      bgImage: "",
      minHeight: 300,
      minWidth: 500,
      isMinimize: true,
      isFullScreen: false,
      isResizeScreen: false,
      isTitleIcon: false,
      isShowDesktop: true,
      position: {
        x: 250,
        y: 90,
      },
      // icon: "https://cdn-icons-png.flaticon.com/512/747/747547.png",
      // icon: "https://cdn-icons-png.flaticon.com/512/4379/4379926.png",
      // icon: "https://cdn-icons-png.flaticon.com/512/724/724827.png",
      // icon: "https://cdn-icons-png.flaticon.com/512/16324/16324381.png",
      // icon: "https://cdn-icons-png.flaticon.com/512/10256/10256203.png",
      // icon: "https://cdn-icons-png.flaticon.com/512/2879/2879442.png",
      icon: <FontAwesomeIcon icon={["fas", "cloud-arrow-down"]} />,
    },
    {
      pageId: "13766-33768",
      isOpen: false,
      title: "Genesys Cloud Import Wizard",
      width: "700",
      height: "580",
      bgColor: "#ffffff",
      bgImage: "",
      minHeight: 300,
      minWidth: 500,
      isMinimize: true,
      isFullScreen: false,
      isResizeScreen: false,
      isTitleIcon: false,
      isShowDesktop: true,
      position: {
        x: 250,
        y: -32,
      },
      // icon: "https://i.postimg.cc/0rR19BRf/import-ico.png",
      // icon: "https://cdn-icons-png.flaticon.com/512/4379/4379926.png",
      // icon: "https://cdn-icons-png.flaticon.com/512/10256/10256203.png",
      icon: <FontAwesomeIcon icon={["fas", "cloud-arrow-up"]} />,
    },
    // {
    //   pageId: "13766-33769",
    //   isOpen: false,
    //   title: "Test Grid",
    //   width: "1070",
    //   height: "580",
    //   bgColor: "#ffffff",
    //   bgImage: "",
    //   minHeight: 580,
    //   minWidth: 1070,
    //   isMinimize: true,
    //   isFullScreen: false,
    //   isResizeScreen: true,
    //   isTitleIcon: true,
    //   isShowDesktop: false,
    //   position: {
    //     x: 250,
    //     y: -130,
    //   },
    //   // icon: "https://i.postimg.cc/0rR19BRf/import-ico.png",
    //   // icon: "https://cdn-icons-png.flaticon.com/512/4379/4379926.png",
    //   icon: "https://cdn-icons-png.flaticon.com/512/2951/2951553.png",
    // },
    // {
    //   pageId: "13766-33761",
    //   isOpen: false,
    //   title: "Personal",
    //   width: "700",
    //   height: "580",
    //   bgColor: "#ffffff",
    //   bgImage: "",
    //   minHeight: 300,
    //   minWidth: 500,
    //   isMinimize: true,
    //   isFullScreen: false,
    //   isResizeScreen: false,
    //   isTitleIcon: true,
    //   isShowDesktop: true,
    //   position: {
    //     x: 250,
    //     y: -30,
    //   },
    //   // icon: "https://i.postimg.cc/0rR19BRf/import-ico.png",
    //   // icon: "https://cdn-icons-png.flaticon.com/512/4379/4379926.png",
    //   icon: "https://a35.dsmdemo.synologydemo.com:5001/webman/modules/PersonalSettings/images/2x/Options_48.png?v=72806-s0",
    // },
  ];

  useEffect(() => {
    if (data) {
      setFolders(
        data?.map((item: any) => ({
          ...item,
          id: item.pageId,
          isOpen: false,
          folderName: item.title,
        }))
      );
    }
    if (exportScriptData) {
      setExportScriptList(exportScriptData?.map((item: any) => item.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportScriptData]);

  useEffect(() => {
    if (error?.message === "The incoming token has expired") {
      SessionStorageUtil.remove("accessToken");
    }
  }, [error]);

  const signOut = async () => {
    try {
      await authSignOut();
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  return {
    isLoading: isLoading,
    folders,
    modalState,
    setFolders,
    setModalState,
    exportValues,
    setProgress,
    setExportValues,
    importValues,
    setImportValues,
    setStep,
    updateFolders,
    signOut,
    username: userDetails.username,
    hideAboutModal,
    showAboutModal,
    aboutModalVisible,
    dateFormate,
    timeFormate,
    desktopIconSize,
    desktopTextColor,
    setWindow,
    isWindow,
    showWindowModal,
    hideWindowModal,
  };
};

export default usePageContainer;
