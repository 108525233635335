import { get, post, put, del, patch } from "aws-amplify/api";
import { toast } from "react-toastify";

interface ErrorResponse {
  message: string;
}

class HttpClient {
  private apiName: string;

  constructor(apiName: string) {
    this.apiName = apiName;
  }

  async get<T>(
    path: string,
    options?: Omit<any, "apiName" | "path">
  ): Promise<T> {
    return this.request<T>(get, path, options);
  }

  async post<T>(
    path: string,
    options?: Omit<any, "apiName" | "path">
  ): Promise<T> {
    return this.request<T>(post, path, options);
  }

  async put<T>(
    path: string,
    options?: Omit<any, "apiName" | "path">
  ): Promise<T> {
    return this.request<T>(put, path, options);
  }

  async delete<T>(
    path: string,
    options?: Omit<any, "apiName" | "path">
  ): Promise<T> {
    return this.request<T>(del, path, options);
  }

  async patch<T>(
    path: string,
    options?: Omit<any, "apiName" | "path">
  ): Promise<T> {
    return this.request<T>(patch, path, options);
  }

  private async request<T>(
    method: typeof get | typeof post | typeof put | typeof del | typeof patch,
    path: string,
    options?: any
  ): Promise<T> {
    try {
      const { body } = (await method({
        apiName: this.apiName,
        path,
        ...options,
      }).response) as any;
      return (await body.json()) as T;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  private handleError(error: unknown | any): void {
    if (error instanceof Error && "response" in error) {
      const { message } = this.parseErrorResponse(error as any);
      this.showErrorToast(message);
    } else {
      console.error("Unexpected error:", error);
    }
  }

  private parseErrorResponse(
    error: Error & { response: { body: string } }
  ): ErrorResponse {
    try {
      return JSON.parse(error.response.body) as ErrorResponse;
    } catch {
      return { message: "An unknown error occurred" };
    }
  }

  private showErrorToast(message: string): void {
    setTimeout(() => {
      toast.error(message, { autoClose: 2000 });
    }, 100);
  }
}

export default HttpClient;
