import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";

interface CustomTextFieldProps {
  props: TextFieldProps;
  errorMessage?: string | boolean;
  inputWidth?: number;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({ props }) => {
  return (
    <>
      <TextField
        {...props}
        sx={{
          "& textarea": {
            fontSize: "13px",
          },

          "& .error_icon": {
            display: !props.error ? "none" : "",
          },

          "& .Mui-disabled": {
            "& fieldset": {
              borderColor: "#d7e0e9 !important",
            },
          },
          "& .MuiInputBase-root": {
            "& fieldset": {
              borderColor: props.error ? "#d32f2f !important" : "#c6d4e0b3",
            },

            "&:hover fieldset": {
              borderColor: props.error ? "#d32f2f !important" : "#c6d4e0b3",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#2993D1",
              borderWidth: "1px",
            },
          },
          "& .MuiFormHelperText-contained": {},
          "& .MuiFormHelperText-root": {
            position: "absolute",
            left: "-26px",
            fontSize: "20px",
            marginTop: "0px",
            marginLeft: 0,
            marginRight: 0,
          },
          "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              background: "#ff00000f !important",
            },
          },
          "& input": {
            padding: "3px 11px",
            paddingRight: props.name === "secret" ? 0 : "",
            fontSize: 13,
            fontFamily: "Verdana,Arial,sans-serif",
            color: "#414B55",
            height: 22,
            outline: "none",
            borderColor: "#414B55",
          },
        }}
      />
    </>
  );
};

export default CustomTextField;
