import useSWR from "swr";
import http from "../../../services/http";

const useJobDetailsHook = () => {
  const { data: exportScriptData, isLoading: exportScriptLoading } = useSWR(
    `/resource/script`,
    http.fetchFromAmplify,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  return {
    isLoading: exportScriptLoading,
    exportScriptList: exportScriptData || ([] as any),
  };
};

export default useJobDetailsHook;
