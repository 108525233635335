import {
  DataGrid,
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  Position,
  Paging,
  Pager,
  SearchPanel,
} from "devextreme-react/data-grid";
// import { SelectBox, SelectBoxTypes } from "devextreme-react/select-box";
// import { CheckBox, CheckBoxTypes } from "devextreme-react/check-box";
import styles from "./styles.module.scss";
import { Box } from "@mui/material";

const searchEditorOptions = { placeholder: "Search column" };
// const columnChooserModeLabel = { "aria-label": "Column Chooser Mode" };

const TestGrid = () => {
  const dataSourceOptions = {
    store: {
      type: "odata" as const,
      version: 2,
      url: "https://js.devexpress.com/Demos/DevAV/odata/Products",
      key: "Product_ID",
    },
    select: [
      "Product_ID",
      "Product_Name",
      "Product_Cost",
      "Product_Sale_Price",
      "Product_Retail_Price",
      "Product_Current_Inventory",
    ],
    filter: ["Product_Current_Inventory", ">", 0],
  };
  //   const [mode, setMode] = useState(columnChooserModes[1].key);
  //   const [searchEnabled, setSearchEnabled] = useState(true);
  //   const [allowSelectAll, setAllowSelectAll] = useState(true);
  //   const [selectByClick, setSelectByClick] = useState(true);
  //   const [recursive, setRecursive] = useState(true);

  //   const isDragMode = mode === columnChooserModes[0].key;

  //   const onModeValueChanged = useCallback(
  //     (e: SelectBoxTypes.ValueChangedEvent) => {
  //       setMode(e.value);
  //     },
  //     []
  //   );

  //   const onSearchEnabledValueChanged = useCallback(
  //     (e: CheckBoxTypes.ValueChangedEvent) => {
  //       setSearchEnabled(e.value);
  //     },
  //     []
  //   );

  //   const onAllowSelectAllValueChanged = useCallback(
  //     (e: CheckBoxTypes.ValueChangedEvent) => {
  //       setAllowSelectAll(e.value);
  //     },
  //     []
  //   );

  //   const onSelectByClickValueChanged = useCallback(
  //     (e: CheckBoxTypes.ValueChangedEvent) => {
  //       setSelectByClick(e.value);
  //     },
  //     []
  //   );

  //   const onRecursiveValueChanged = useCallback(
  //     (e: CheckBoxTypes.ValueChangedEvent) => {
  //       setRecursive(e.value);
  //     },
  //     []
  //   );

  return (
    <Box height={"100%"} id="grid_wrapper">
      <DataGrid
        id="data_grid"
        dataSource={dataSourceOptions}
        keyExpr="ID"
        columnAutoWidth={true}
        showRowLines={true}
        width="100%"
        height={"100%"}
        showBorders={true}
        elementAttr={{
          id: "data_grid",
          class: styles.data_grid_wrapper,
        }}
      >
        <SearchPanel
          searchVisibleColumnsOnly
          visible={true}
          highlightCaseSensitive={true}
        />
        <Column
          dataField="Product_ID"
          caption="Product Id"
          alignment="left"
          allowResizing
        />
        <Column dataField="Product_Name" alignment="left" allowResizing />
        <Column
          dataField="Product_Cost"
          caption="Cost"
          dataType="number"
          format="currency"
          alignment="left"
          allowResizing
        />
        <Column
          dataField="Product_Sale_Price"
          caption="Sale Price"
          dataType="number"
          format="currency"
          alignment="left"
          allowResizing
        />
        <Column
          dataField="Product_Retail_Price"
          caption="Retail Price"
          dataType="number"
          format="currency"
          alignment="left"
          allowResizing
        />
        <Column
          dataField="Product_Current_Inventory"
          caption="Inventory"
          alignment="left"
          allowResizing
        />
        <Paging defaultPageSize={12} />
        <Pager
          visible={true}
          showInfo
          showNavigationButtons
          displayMode="full"
          label="test"
          showPageSizeSelector={true}
        />

        <ColumnChooser
          height="308px"
          enabled={true}
          mode={"select"}
          container={"#data_grid"}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />

          <ColumnChooserSearch
            enabled={true}
            editorOptions={searchEditorOptions}
          />

          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true}
          />
        </ColumnChooser>
      </DataGrid>
    </Box>
  );
};

export default TestGrid;
