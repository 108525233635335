import { memo, useCallback, useEffect, useState } from "react";
import { Popup } from "devextreme-react/popup";
import { Box, InputAdornment, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import styles from "./styles.module.scss";
import { useMainCtx } from "../../redux/store/useMainCtx";
import ContextMenu, { ContextMenuTypes } from "devextreme-react/context-menu";

const contextOptions = [
  {
    text: "Add to desktop",
  },
];

export const Window = ({ popupVisible, hide, toggleFolder }: any) => {
  const { folders, updateFolders } = useMainCtx((state) => {
    return {
      folders: state.folders,
      updateFolders: state.updateFolders,
    };
  });

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(folders);
  const [width, setWidth] = useState("110vh");
  const [height, setHeight] = useState("100vh");

  const handleResizeEnd = useCallback(
    ({ height, width }: any) => {
      setHeight(height);
      setWidth(width);
    },
    [setWidth, setHeight]
  );

  // Handle drag start
  const handleContextClick = async (
    e: ContextMenuTypes.ItemClickEvent,
    folder: any
  ) => {
    if (!e?.itemData?.items) {
      const { text } = e.itemData as any;
      if (text === contextOptions[0].text) {
        const _folders = [...folders];
        const _folderIdx = _folders.findIndex((fold) => fold.id === folder.id);
        _folders[_folderIdx] = {
          ..._folders[_folderIdx],
          isShowDesktop: true,
        };
        updateFolders(_folders);
        hide();
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);

    // Filter the data based on the search value
    const filtered = folders.filter(
      (item) => item.folderName.toLowerCase().includes(value) // Adjust property as needed
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    if (popupVisible) {
      setTimeout(() => {
        document.getElementById("search_field")?.focus();
      }, 500);
    }
  }, [popupVisible]);

  return (
    <Box className={styles.window_modal_wrapper} onClick={() => hide()}>
      <Popup
        id="window_modal_wrapper"
        wrapperAttr={{
          id: "window_modal_wrapper",
          class: styles.window_modal_wrapper,
        }}
        width={width}
        height={height}
        visible={popupVisible}
        onHiding={hide}
        hideOnOutsideClick={true}
        showCloseButton={false}
        resizeEnabled={false}
        dragEnabled={false}
        enableBodyScroll={false}
        deferRendering={true}
        onResizeEnd={handleResizeEnd}
        onVisibleChange={(value: any) => console.log("value", value)}
      >
        <Box height={"100vh"}>
          <Box
            textAlign={"center"}
            pt={"52px"}
            pb={"18px"}
            onClick={() => hide()}
          >
            <TextField
              id="search_field"
              sx={{
                "& .MuiInputBase-root": {
                  height: 36,
                  fontSize: "13px",
                  width: 278,
                  paddingLeft: "8px",
                  background: "#28323c",
                  color: "#e0eaf4",
                  borderRadius: "4px",
                  "& fieldset": {
                    borderColor: "#58626c",
                  },
                  "&:hover fieldset": {
                    borderColor: "#c6d4e0b3",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#2993d1",
                    borderWidth: "1px",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#959fa9 !important",
                  },
                },
              }}
              inputProps={{
                autoFocus: true,
              }}
              size="small"
              variant="outlined"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "black", fontSize: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              "& ul": {
                padding: 0,
                listStyleType: "none",
                display: "flex",
                flexWrap: "wrap",
                gap: "21px",
                marginTop: "21px",
                justifyContent: "center",
                "& li": {
                  textDecoration: "none",
                },
              },
            }}
            onClick={() => hide()}
          >
            <ul>
              {filteredData
                .filter((item) => item.folderName !== "Personal")
                .map((item, index) => {
                  const folderId =
                    item?.folderName.replaceAll(" ", "_") + index;
                  return (
                    <Tooltip title={""} followCursor placement="right">
                      <li
                        id={folderId}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        draggable
                        onDragStart={() => {
                          setTimeout(() => {
                            hide();
                          }, 1000);
                        }}
                        onDragEnd={async () => {
                          hide();
                          const _folders = [...folders];
                          const _folderIdx = _folders.findIndex(
                            (fold) => fold.id === item.id
                          );
                          _folders[_folderIdx] = {
                            ..._folders[_folderIdx],
                            isShowDesktop: true,
                          };
                          await updateFolders(_folders);
                        }}
                        style={{
                          // border: "1px solid red",
                          width: "156px",
                          height: "124px",
                          padding: "16px 8px 16px 8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="folders_icons_wrap"
                          onClick={() => {
                            hide();
                            toggleFolder(item.id);
                          }}
                          style={{
                            width: "122px",
                            height: "120px",
                            margin: 0,
                            boxSizing: "unset",
                            display:
                              item.folderName === "Personal" ? "none" : "",
                          }}
                        >
                          <div
                            className="folder_icon"
                            style={{
                              // backgroundImage: `url(${
                              //   item.icon && item?.icon?.includes("/assets/")
                              //     ? "/assets/icons/large_folder.png"
                              //     : item.icon
                              // } )`,
                              width: "72px",
                              height: "72px",
                              backgroundSize: "72px 72px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                "& svg": {
                                  fontSize: "50px",
                                  color: "#f3f5f6",
                                },
                              }}
                              children={item.icon}
                            />
                          </div>
                          <span
                            style={{
                              textShadow: "0em .1em .2em rgba(0,0,0,.8)",
                              // color: desktopTextColor,
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: 400,
                              height: 36,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.folderName}
                          </span>
                        </div>

                        <ContextMenu
                          cssClass={styles.content_menu_wrapper}
                          dataSource={contextOptions}
                          target={`#${folderId}`}
                          onItemClick={(e) => handleContextClick(e, item)}
                        />
                      </li>
                    </Tooltip>
                  );
                })}
            </ul>
          </Box>
        </Box>
      </Popup>
    </Box>
  );
};

export default memo(Window);
