import useSWR from "swr";
import { API_URL } from "../../../utils";
import { useEffect } from "react";
import SessionStorageUtil from "../../../services/session";
import http from "../../../services/http";

export const useHook = () => {
  const getExportIds = (url: string) => http.apiRequest(url, "GET");

  const {
    data: exportScriptData,
    isLoading: exportScriptLoading,
    error,
  } = useSWR(`${API_URL.GET_EXPORT_IDS}`, getExportIds, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  });

  useEffect(() => {
    if (error?.message === "The incoming token has expired") {
      SessionStorageUtil.remove("accessToken");
    }
  }, [error]);

  return {
    isLoading: exportScriptLoading,
    exportScriptList:
      exportScriptData && exportScriptData.length > 0
        ? exportScriptData?.map((item: any) => item.id)
        : [],
  };
};
